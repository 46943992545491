/*******************************************
Global Variables
*******************************************/

// General Sizing and Padding

$main-header-height: 44px;
$page-header-height: 44px;
// $main-footer-height: 24px;
$main-footer-height: 44px;
$sidebar-width-left: 188px;
$sidebar-width-left-collapsed: 35px;
$sidebar-width-content: 311px;
$sidebar-width-right: 320px;
$map-sidebar-width: 400px;
$map-header-height: 44px;
$show-publish-alert: 61px;
$upload-modal-width: 240px;
$wallet-connect-modal-width: 540px;


$nft-project-width: 240px;
$nft-width: 240px;

// Text

$light: 300;
$normal: 400;
$semi-bold: 600;
$bold: 700;
$xbold: 800;

// Colors

$color-modal-background: rgba(11,11,11,.73);
$color-modal-content-background: rgba(21,27,33,1);

$color-panel-main: rgba(0,0,0,.33);
$color-panel-header-light: rgba(22,22,22,.88);
$color-panel-header-lighter: rgba(31,31,31,.88);
// $color-panel-sub: rgb(20 21 22 / 100%);
$color-panel-sub: rgb(13 17 21 / 53%);
$color-panel-sub-full: rgb(23 27 31 / 95%);
$color-panel-sub-dark: rgb(24,30,34,.77);
$color-panel-sub-darker: rgb(17,19,22,.73);
$color-panel-dark: rgba(0,0,0,.44);
$color-panel-sub-opaque: rgb(12 16 19 / 77%);
$color-panel-sub-opaque-dark: rgb(12 16 19 / 69%);
$color-panel-sub-opaque-light: rgb(19 21 23 / 55%);


$color-panel-light: rgb(48 51 54 / 90%);
// $color-panel-sub-item: rgb(22 26 30 / 89%);
$color-panel-sub-item: rgba(11,11,11, 0.77);
$color-panel-sub-item-solid: rgba(7, 17, 21, 1);
$color-panel-sub-item-dark: rgb(11 11 11 / 88%);
$color-panel-sub-item-pop: rgba(44,55,55,.5);
$color-panel-sub-item-roll: rgba(16,16,16,.84);
$color-panel-sub-item-active: rgba(0,0,0,.64);
$color-panel-sub-item-active-solid: rgba(0,0,0,.64);
$color-panel-sub-item-border: 1px solid rgb(72 96 113 / 15%);
$color-panel-sub-roll: rgba(88,88,88,.5);
$color-panel-data: rgb(19 23 27 / 84%);
$color-panel-data-opaque: rgb(19 23 27 / 88%);
$color-panel-input: #171717;
$border-section: 4px solid #0808084f;

$color-panel-button: rgba(7,17,21,.44);
$color-panel-button-solid: rgb(21, 24, 27);
$color-panel-button-roll: #111111;

$color-panel-title-subtle: #373737;



$color-off-white: #f2f2f2;
$color-grey-dark: #272727;
$color-grey-darker: #222222;
$color-grey-darkest: #1b1b1b;
$color-grey01: #82909a;
$color-grey02: #596e7d;
$color-grey03: #163449;
$color-grey-light: #E1E4E6;
$color-grey-lighter: #e9eaec;
$color-blue-lightest: #8199a9;
$color-black: #000;
$color-white: #fbfbfb;

$color-red: #fe2948;
$color-yellow: #ffc138;
$color-orange: #F0740E;
$color-orange-carto: #9f3330;
$color-orange-dark: #c74305;
$color-green: #01be8c;
$color-green-standard: #008000;
$color-green-neon: #00ffbc;
$color-green-light: #6bf178;
$color-blue: #05a0c7;
$color-blue-light: #4dc1f0;
$color-blue-light-opaque: #4dc2f05d;
$color-blue-lighter: #b6d7ec;
$color-blue-lightest: #a4cce8;
$color-purple: #9779fb;

$color-brand-blue: #3897f0;
// $color-brand-blue: #00efb6;
$color-brand-green: #40dc5a;
$color-winter: #F2F4F7;
$color-frost: rgb(42,131,161);
$color-plum: #241e38;
$color-night: #1a1c1c;



$color-panel-title: $color-frost;
$color-data-title: #b0b8bfa1;
$color-data-value: $color-brand-blue;


$color-brand: $color-blue-light;
$color-brand-opaque: $color-blue-light-opaque;
$color-brand2: $color-orange;
$color-subtitle-blue: #7acbd0;

$color-item-dark: #131718;

$color-background-dark-light: #222;
$color-background-dark: $color-night;
$color-background-darker: #191a1a;
$color-background-darkest: #151717;
$color-background-light: $color-winter;
$color-placeholder: $color-grey01;
$color-placeholder-dark: #d4d4d4;
$color-text-light: $color-blue-lighter;
$color-text-medium: #99b9d0;
$color-text-active: $color-brand;
$color-text: $color-blue-lightest;
$color-text-button: $color-black;
$color-link: #d9d9d9;
$color-edit-text: #1c3c52;
$color-nav-active: $color-brand2;

$color-count-background: $color-brand-blue;
$color-count-text: $color-black;

$color-icon-medium: $color-text-medium;
$color-icon: $color-text;
$color-icon-roll: $color-brand;
$color-icon-active: $color-brand;

$color-positive: $color-blue;
$color-warn: $color-orange;
$color-danger: #ff6415;

$color-button-default: #11141591;

$color-button-default-text: #a09e9e;
$color-button-neutral: rgba(108,108,108,.22);
$color-button-action: $color-brand;
$color-button-action-active: rgba(73,73,73,.8);
$color-button-positive: $color-blue;
$color-field-default: $color-panel-input;
$color-field-dark: rgba(184, 166, 191, 0.3);
$color-field-active: $color-blue-lightest;

$color-border: #0e3b5a;
$color-border: #232d345a;
$color-border-dex: #232d345a;
$color-border-dark: rgba($color-border, 0.22);

$color-main-menu-btn: #A7BAC7;

$color-drop-zone: rgba($color-brand, 0.7);

// Border radius
$radius-xs: 2px;
$radius-sm: 4px;
$radius-md: 6px;
$radius-lg: 8px;
$radius-xl: 10px;
$radius-xxl: 16px;
$radius-video: $radius-lg;

// Transition Durations

$duration-notification: .2s;
$duration-transition-long: 1s;
$hover-duration: 0.15s;
$input-duration: 0.15s;
$drawer-duration: 0.4s;
$modal-duration: 0.25s;
$popover-duration: 0.15s;
$alert-duration: 0.15s;
$controls-duration: 0.25s;
$tab-active: 0.3s;

// Z-index values

$z-index-modal: 100;
$z-index-header: 99;
$z-index-controls: 99;
$z-index-local-media: 3;
$z-index-remote-media: 2;
$z-index-empty: 1;


$item-border: 1px solid #222222a0;

// shadow
$shadow-tight: 0px 0px 3px 1px rgb(0 0 0 / 30%);
$shadow-panel: 0px 0px 2px 1px rgb(0 0 0 / 30%);
$shadow-panel-sub: 0px 0px 2px 2px rgba(0, 0, 0, 0.4);
$shadow-panel-sub-light: 0px 0px 1px 1px rgba(53, 53, 53, 0.4);

// scene editor constants
$keyframe-width: 88px;
$keyframe-margin-right: 8px;
$keyframe-margin-left: 8px;
