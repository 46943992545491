/* saira-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Saira';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/saira-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saira-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira-v14-latin-300.svg#Saira') format('svg'); /* Legacy iOS */
}
/* saira-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/saira-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saira-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira-v14-latin-regular.svg#Saira') format('svg'); /* Legacy iOS */
}
/* saira-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Saira';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/saira-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saira-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira-v14-latin-600.svg#Saira') format('svg'); /* Legacy iOS */
}
/* saira-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Saira';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/saira-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saira-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/saira-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/saira-v14-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/saira-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/saira-v14-latin-800.svg#Saira') format('svg'); /* Legacy iOS */
}


/* roboto-mono-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-mono-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-mono-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-mono-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-mono-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-mono-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-mono-v22-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-mono-v22-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-mono-v22-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-mono-v22-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-mono-v22-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-mono-v22-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-mono-v22-latin-500.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
