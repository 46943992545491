/*******************************************
Speciatly Buttons
*******************************************/

// Icon buttons

.icon-btn,
a.icon-btn {
    cursor: pointer;
    display: block;
    font-size: 20px;

    > i {
        display: block;
        color: $color-text-medium;
    }

    &:hover {
        text-decoration: none;

        > i {
            color: $color-text;
        }
    }

    &.small {
        font-size: 16px;
    }

    &.large {
        font-size: 24px;
    }
}

// Raised button

.raised-btn {
    display: flex;
    background: #1e1e1e;
    border: 1px solid $color-border;
    border-radius: $radius-xl;
    box-shadow: 0 2px 6px -3px rgba(0, 0, 0, 0.1);
    color: $color-text;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin: 12px 0;
    width: 100%;
    transition: border-color $hover-duration;

    > i {
        transition: color $hover-duration;
    }

    &:hover {
        background-color: white;
        border-color: $color-blue;

        > i {
            color: $color-blue;
        }
    }

    i:not(.icon-arrow-right) {
        font-size: 24px;
        margin-right: 10px;
    }

    i.icon-arrow-right {
        margin-left: auto;
        font-size: 20px;
    }

    &.embed-option {
        line-height: normal;
        padding: 20px;
        margin: 20px 0;

        .embed-img {
            display: block;
            width: 90px;
            height: auto;
            margin-right: 20px;
        }

        h5 {
            margin: 0 0 5px !important;
        }
    }
}

.tech-btn-svg {
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;

    margin-right: 13px;
    svg{
        width: 20px;
        height: 20px;
        fill: #a4cce8;
    }
}

.tech-btn {
    display: flex;
    background: #272727;

    box-shadow: none;
    color: $color-text;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    margin: 12px 0;
    margin: 0;
    margin-top: 1px;
    border-radius: 0;
    width: 100%;
    transition: border-color $hover-duration;





    > i {
        transition: color $hover-duration;
    }

    &:hover {
        background-color: #1e1e1e;
        // border-color: $color-blue;

        .tech-btn-svg{
            svg {
                fill: $color-blue;
            }

        }

        > i {
            color: $color-blue;
        }
    }

    i:not(.icon-arrow-right) {
        font-size: 24px;
        margin-right: 10px;
    }

    i.icon-arrow-right {
        margin-left: auto;
        font-size: 20px;
    }

    &.embed-option {
        line-height: normal;
        padding: 20px;
        margin: 20px 0;

        .embed-img {
            display: block;
            width: 90px;
            height: auto;
            margin-right: 20px;
        }

        h5 {
            margin: 0 0 5px !important;
        }
    }
}

// Image buttons (e.g. Embed Location)

.image-button {
    @include container-raised();
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    &::after {
        content: "";
        display: block;
        padding-bottom: 72%;
    }

    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;

        > * {
            display: block;
        }

        > i {
            color: $color-text-light;
            font-size: 32px;
            margin-bottom: 10px;
        }

        .meta {
            margin-bottom: 20px;
        }

        > img {
            display: block;
            object-fit: cover;
            width: inherit;
            height: inherit;
        }
    }

    .image-info {
        background-color: rgba($color-off-white, 0.85);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 12px 15px;

        a {
            display: block;
            font-size: 13px;
            max-width: 100%;
            @include truncate();

            &:hover {
                white-space: pre-wrap;
            }
        }
    }
}

.chrome-picker{
    background: #292929 !important;

    svg{
        fill: #fff !important;
        &:hover{
            background: #222 !important;
        }
    }

    label{
        color: #fff !important;
    }
}

.icon-action-button {
    position: absolute;
    &.relative{
        position: relative;
    }
    padding: 5px;
    background: $color-panel-button;
    &.transparentbg{
        background: transparent;
    }
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color $input-duration;
    right: initial;
    top: initial;
    bottom: initial;
    left: initial;
    z-index: 2;

    font-size: 22px;

    &.top-right{
        top: 15px;
        right: 15px;
    }
    &.top-left{
        top: 15px;
        left: 5px;
    }
    &.center-right{
        top: 94%;
        right: 15px;
    }
    &.center-left{
        top: 50%;
        top: 94%;
        left: 5px;
    }
    &.sidebar-right{
        right:0;
        padding: 16px 4px;
        margin-top:-22px;
        border-radius: 7px 0 0 7px;
        background: rgb(0 0 0 / 64%);
    }

    &.sidebar-left{
        left:0;
        margin-top:-22px;
        padding: 16px 4px;
        border-radius: 0 7px 7px 0;
        background: rgb(0 0 0 / 64%);
    }

    svg{
        width: 18px !important;
        height: 18px !important;
    }

    *{
        color:$color-grey01;
        fill:$color-grey01;
    }

    // &.active{
    //     *{
    //         color:$color-grey-darker;
    //         fill:$color-grey-darker;
    //         stroke: $color-grey-darker;
    //     }
    //     background: $color-button-action-active;
    // }


    &:hover,&.active{
        // background: $color-button-action;
        // *{
        //     color:$color-grey-darker;
        //     fill:$color-grey-darker;
        //     stroke: $color-grey-darker;
        // }
        background: $color-panel-button !important;
        *{
            color:$color-icon-roll;
            fill:$color-icon-roll;
            stroke: $color-icon-roll;
        }
    }
}





.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
//   background-color: #4D4D4D;
  background: linear-gradient(352deg, rgb(42 42 42 / 84%) 0%, rgb(99 99 99 / 54%) 82%, rgb(182 182 182 / 58%) 99%);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
//   background-color: #00000069;
    background: linear-gradient(352deg, rgb(26 47 22 / 84%) 0%, rgb(30 150 11 / 54%) 82%, rgb(30 150 11 / 58%) 99%);
}

.react-toggle--checked .react-toggle-track {
//   background-color: #19AB27;
  background-image: linear-gradient(352deg, rgb(26 47 22 / 84%) 0%, rgb(30 150 11 / 54%) 82%, rgb(30 150 11 / 58%) 99%);
  // background-color: #348919;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  // border: 1px solid #4D4D4D;
  border-radius: 50%;
//   background-color: #b3b0b0;


  border: 1px solid #333; /* Dark grey border */
  background: radial-gradient(circle, #737373 0%, #5d5d5d 70%, #332 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), inset 0 1px 2px rgba(255, 255, 255, 0.1);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #069c15;
}

.react-toggle--focus .react-toggle-thumb {
  // -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  // -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  // box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  // -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  // -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  // box-shadow: 0px 0px 5px 5px #0099E0;
}
