/*******************************************
base classes
*******************************************/

.flex-row {
    display: flex;
    flex-direction: row;
    &.justify{
        &.center{
            justify-content: center;
        }
        &.space-between{
            justify-content: space-between;
        }
        &.space-around{
            justify-content: space-around;
        }
    }
    &.align{
        &.center{
            align-items: center;
        }
        &.space-between{
            align-items: space-between;
        }
        &.space-around{
            align-items: space-around;
        }
    }
    &.full{
        width: 100%;
    }
    &.interactive{

    }

    &.interactive{
		cursor: pointer;
		&:hover, &.active{
			// background: $color-panel-button-roll !important;
			background: $color-panel-button !important;
			background: $color-panel-data !important;

		}

	}
	// &.active{
	// 	// background: $color-panel-button-roll !important;
	// 	background: $color-panel-data !important;
	// 	// padding: 12px;
	// 	.data-title{
	// 		color: $color-text !important;
	// 	}
	// }
}

.flex-column {
    display: flex;
    flex-direction: column;
    &.full-height{
        height:100vh;
        min-height: -webkit-fill-available;
    }
    &.justify{
        &.center{
            justify-content: center;
        }
        &.space-between{
            justify-content: space-between;
        }
    }
    &.align{
        &.center{
            align-items: center;
        }
        &.space-between{
            align-items: space-between;
        }
    }
    &.full{
        width: 100%;
    }
}

.wrap{
    flex-wrap: wrap;
}

.side-pad{
    padding: 0 15px;
}
