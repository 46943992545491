@font-face {
  font-family: "artemis-icons";
	src:
  url('../icons/artemis-icons.ttf?x51o62') format('truetype'),
  url('../icons/artemis-icons.woff?x51o62') format('woff'),
  url('../icons/artemis-icons.svg?x51o62#artemis-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icons() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'artemis-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  &::before {
      display: block;
      position: relative;
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  @include icons();
}

.icon-download:before {
  content: "\e948";
}
.icon-video-camera:before {
  content: "\e947";
}
.icon-grid:before {
  content: "\e946";
}
.icon-code:before {
  content: "\e945";
}
.icon-alert:before {
  content: "\e921";
}
.icon-archive:before {
  content: "\e922";
}
.icon-arrow-down:before {
  content: "\e924";
}
.icon-arrow-down-thick:before {
  content: "\e923";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-arrow-left-thick:before {
  content: "\e925";
}
.icon-arrow-right:before {
  content: "\e918";
}
.icon-arrow-right-thick:before {
  content: "\e926";
}
.icon-arrow-up:before {
  content: "\e928";
}
.icon-arrow-up-thick:before {
  content: "\e927";
}
.icon-calendar:before {
  content: "\e929";
}
.icon-check:before {
  content: "\e915";
}
.icon-chevron-down:before {
  content: "\e900";
}
.icon-chevron-left:before {
  content: "\e92a";
}
.icon-chevron-right:before {
  content: "\e92b";
}
.icon-chevron-top:before {
  content: "\e92c";
}
.icon-close:before {
  content: "\e901";
}
.icon-close-right:before {
  content: "\e92d";
}
.icon-close-thin:before {
  content: "\e92e";
}
.icon-copy:before {
  content: "\e92f";
}
.icon-device-desktop:before {
  content: "\e930";
}
.icon-device-mobile:before {
  content: "\e931";
}
.icon-device-tablet:before {
  content: "\e932";
}
.icon-ellipsis-horizontal:before {
  content: "\e902";
}
.icon-ellipsis-vertical:before {
  content: "\e903";
}
.icon-enlarge:before {
  content: "\e904";
}
.icon-expand:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e91d";
}
.icon-hamburger:before {
  content: "\e905";
}
.icon-hang-up:before {
  content: "\e916";
}
.icon-help:before {
  content: "\e933";
}
.icon-home:before {
  content: "\e935";
}
.icon-home-thick:before {
  content: "\e934";
}
.icon-image:before {
  content: "\e936";
}
.icon-leave:before {
  content: "\e906";
}
.icon-link:before {
  content: "\e937";
}
.icon-linkedin:before {
  content: "\e91e";
}
.icon-lock:before {
  content: "\e938";
}
.icon-message:before {
  content: "\e93a";
}
.icon-message-solid:before {
  content: "\e939";
}
.icon-messages:before {
  content: "\e93b";
}
.icon-mic:before {
  content: "\e908";
}
.icon-mic-off:before {
  content: "\e907";
}
.icon-mic-solid:before {
  content: "\e91b";
}
.icon-mic-solid-off:before {
  content: "\e919";
}
.icon-minimize:before {
  content: "\e909";
}
.icon-pencil:before {
  content: "\e93c";
}
.icon-plus-thick:before {
  content: "\e93d";
}
.icon-pop-out:before {
  content: "\e90a";
}
.icon-product-hunt:before {
  content: "\e91f";
}
.icon-reduce:before {
  content: "\e93e";
}
.icon-reopen:before {
  content: "\e914";
}
.icon-retract:before {
  content: "\e911";
}
.icon-rotate-right:before {
  content: "\e93f";
}
.icon-screen-share:before {
  content: "\e90b";
}
.icon-settings:before {
  content: "\e90c";
}
.icon-settings-solid:before {
  content: "\e940";
}
.icon-signout:before {
  content: "\e941";
}
.icon-smile-thick:before {
  content: "\e942";
}
.icon-switch-camera:before {
  content: "\e912";
}
.icon-twitter:before {
  content: "\e920";
}
.icon-user-add:before {
  content: "\e90d";
}
.icon-users:before {
  content: "\e943";
}
.icon-video:before {
  content: "\e90f";
}
.icon-video-off:before {
  content: "\e90e";
}
.icon-video-solid:before {
  content: "\e91c";
}
.icon-video-solid-off:before {
  content: "\e91a";
}
