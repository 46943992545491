/*******************************************
Modal
*******************************************/

.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 899;
    background-image: url(/media/textures/micro_grid.png);
	background-repeat: repeat;
}

.wallet-modal {
    background-color: $color-modal-background;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 108;
    overflow: auto;
    pointer-events: none;
    transition: $modal-duration background-color;

    .wallet-content {
            background-color: rgb(10 12 12 / 98%);

        box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 10%), 0 0 1px 1px rgb(0 0 0 / 5%);
        border-radius: $radius-xl;
        width: $wallet-connect-modal-width;
        max-width: 540px;
        overflow: hidden;
        position: relative;
        // opacity: 0;
        transform: scale(.92);
        transition:
            transform $modal-duration ease-in-out,
            opacity $modal-duration ease-in-out;
        pointer-events: initial;
        padding: 45px 50px 50px;

        .icon-action-button{
            right:11px;
            top:11px;
        }

        button{
            width:100%;
            justify-content: flex-start;
            padding: 22px 18px;
            img, svg{
                // max-width: 33px;
                // max-height: 33px;
                min-height: 34px;
                min-width: 34px;
                margin-right: 10px;
            }
        }

        &.small {
            max-width: 480px;
        }

        .close-modal {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &.show {
        background: rgba(22,22,22,0.73);

        .modal-content {
            transform: scale(1);
            opacity: 1;
        }
    }

    &.hide {
        background: transparent;

        .modal-content {
            transform: scale(.92);
            opacity: 0;
        }
    }
}

.securityBlur button.wallet-button{
    filter: blur(4px);
}

button.wallet-button{
    position: relative;
    border-radius: $radius-sm;
    // padding-left: 16px !important;
    // &.active{
    //     padding-left: 28px !important;
    // }
    // background: rgb(28, 33, 38);
    // border-color: rgb(28, 67, 93);
    min-width: 144px !important;
}

.wallet-profile-image{
    position: absolute;
    left: 5px;
    top:5px;
    .identicon{
        max-width: 16px;
        max-height: 15px;
        border-radius: 50%;
        margin-right: 7px;
    }
}



.active-green-circle{
    width:8px;
    height:8px;
    border-radius: 50%;
    background: $color-green;
    position: absolute;
    right:8px;
    top:50%;
    margin-top:-4px;
    &.red{
        background: $color-red !important;
    }
}

.gradient-swatches{
    max-width: 444px;
    margin: 11px;
}

.gradient-swatch{
    cursor: pointer;
    width:22px;
    height: 22px;
    margin:3px;
    border-radius: 3px;
    border: 1px solid #1d1d1d;
    &:hover{
        border-color: $color-button-action;
    }
}
