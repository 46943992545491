@font-face {
  font-family: 'artemis-buttons';
  src:
    url('../icons/artemis-buttons.ttf?4vryqe') format('truetype'),
    url('../icons/artemis-buttons.woff?4vryqe') format('woff'),
    url('../icons/artemis-buttons.svg?4vryqe#artemis-buttons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-button"], [class*=" icon-button"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'artemis-buttons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: $color-text;
  margin-right:8px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.interactive-items{
  [class^="icon-button"], [class*=" icon-button"] {

    margin-right:0px !important;
  
    
  }
}


.main-menu-btn.active, a.main-menu-btn:hover {
    [class^="icon-button"], [class*=" icon-button"] {
        color: $color-blue;
    }
    .main-menu-title{
        .svg-icon{
            svg{
                fill: $color-blue;
                stroke: $color-blue;
            }
        }

    }

}

.select select{
    &.button-icon-select{
        text-transform: capitalize !important;
    }
}

.icon-button-blend:before {
  content: "\e9dd";
}

.icon-button-video:before {
  content: "\e901";
}
.icon-button-video-solid:before {
  content: "\e902";
}
.icon-button-video-camera:before {
  content: "\e903";
}
.icon-button-message:before {
  content: "\e904";
}
.icon-button-message-solid:before {
  content: "\e905";
}
.icon-button-messages:before {
  content: "\e906";
}
.icon-button-activity:before {
  content: "\e907";
}
.icon-button-airplay:before {
  content: "\e908";
}
.icon-button-alert-circle:before {
  content: "\e909";
}
.icon-button-alert-octagon:before {
  content: "\e90a";
}
.icon-button-alert-triangle:before {
  content: "\e90b";
}
.icon-button-align-center:before {
  content: "\e90c";
}
.icon-button-align-justify:before {
  content: "\e90d";
}
.icon-button-align-left:before {
  content: "\e90e";
}
.icon-button-align-right:before {
  content: "\e90f";
}
.icon-button-anchor:before {
  content: "\e910";
}
.icon-button-aperture:before {
  content: "\e911";
}
.icon-button-archive:before {
  content: "\e912";
}
.icon-button-arrow-down:before {
  content: "\e913";
}
.icon-button-arrow-down-circle:before {
  content: "\e914";
}
.icon-button-arrow-down-left:before {
  content: "\e915";
}
.icon-button-arrow-down-right:before {
  content: "\e916";
}
.icon-button-arrow-left:before {
  content: "\e917";
}
.icon-button-arrow-left-circle:before {
  content: "\e918";
}
.icon-button-arrow-right:before {
  content: "\e919";
}
.icon-button-arrow-right-circle:before {
  content: "\e91a";
}
.icon-button-arrow-up:before {
  content: "\e91b";
}
.icon-button-arrow-up-circle:before {
  content: "\e91c";
}
.icon-button-arrow-up-left:before {
  content: "\e91d";
}
.icon-button-arrow-up-right:before {
  content: "\e91e";
}
.icon-button-at-sign:before {
  content: "\e91f";
}
.icon-button-award:before {
  content: "\e920";
}
.icon-button-bar-chart:before {
  content: "\e921";
}
.icon-button-bar-chart-2:before {
  content: "\e922";
}
.icon-button-battery:before {
  content: "\e923";
}
.icon-button-battery-charging:before {
  content: "\e924";
}
.icon-button-bell:before {
  content: "\e925";
}
.icon-button-bell-off:before {
  content: "\e926";
}
.icon-button-bluetooth:before {
  content: "\e927";
}
.icon-button-bold:before {
  content: "\e928";
}
.icon-button-book:before {
  content: "\e929";
}
.icon-button-book-open:before {
  content: "\e92a";
}
.icon-button-bookmark:before {
  content: "\e92b";
}
.icon-button-box:before {
  content: "\e92c";
}
.icon-button-briefcase:before {
  content: "\e92d";
}
.icon-button-calendar:before {
  content: "\e92e";
}
.icon-button-camera:before {
  content: "\e92f";
}
.icon-button-camera-off:before {
  content: "\e930";
}
.icon-button-cast:before {
  content: "\e931";
}
.icon-button-check:before {
  content: "\e932";
}
.icon-button-check-circle:before {
  content: "\e933";
}
.icon-button-check-square:before {
  content: "\e934";
}
.icon-button-chevron-down:before {
  content: "\e935";
}
.icon-button-chevron-left:before {
  content: "\e936";
}
.icon-button-chevron-right:before {
  content: "\e937";
}
.icon-button-chevron-up:before {
  content: "\e938";
}
.icon-button-chevrons-down:before {
  content: "\e939";
}
.icon-button-chevrons-left:before {
  content: "\e93a";
}
.icon-button-chevrons-right:before {
  content: "\e93b";
}
.icon-button-chevrons-up:before {
  content: "\e93c";
}
.icon-button-chrome:before {
  content: "\e93d";
}
.icon-button-circle:before {
  content: "\e93e";
}
.icon-button-clipboard:before {
  content: "\e93f";
}
.icon-button-clock:before {
  content: "\e940";
}
.icon-button-cloud:before {
  content: "\e941";
}
.icon-button-cloud-drizzle:before {
  content: "\e942";
}
.icon-button-cloud-lightning:before {
  content: "\e943";
}
.icon-button-cloud-off:before {
  content: "\e944";
}
.icon-button-cloud-rain:before {
  content: "\e945";
}
.icon-button-cloud-snow:before {
  content: "\e946";
}
.icon-button-code:before {
  content: "\e947";
}
.icon-button-codepen:before {
  content: "\e948";
}
.icon-button-codesandbox:before {
  content: "\e949";
}
.icon-button-coffee:before {
  content: "\e94a";
}
.icon-button-columns:before {
  content: "\e94b";
}
.icon-button-command:before {
  content: "\e94c";
}
.icon-button-compass:before {
  content: "\e94d";
}
.icon-button-copy:before {
  content: "\e94e";
}
.icon-button-corner-down-left:before {
  content: "\e94f";
}
.icon-button-corner-down-right:before {
  content: "\e950";
}
.icon-button-corner-left-down:before {
  content: "\e951";
}
.icon-button-corner-left-up:before {
  content: "\e952";
}
.icon-button-corner-right-down:before {
  content: "\e953";
}
.icon-button-corner-right-up:before {
  content: "\e954";
}
.icon-button-corner-up-left:before {
  content: "\e955";
}
.icon-button-corner-up-right:before {
  content: "\e956";
}
.icon-button-cpu:before {
  content: "\e957";
}
.icon-button-credit-card:before {
  content: "\e958";
}
.icon-button-crop:before {
  content: "\e959";
}
.icon-button-crosshair:before {
  content: "\e95a";
}
.icon-button-database:before {
  content: "\e95b";
}
.icon-button-delete:before {
  content: "\e95c";
}
.icon-button-disc:before {
  content: "\e95d";
}
.icon-button-dollar-sign:before {
  content: "\e95e";
}
.icon-button-download:before {
  content: "\e95f";
}
.icon-button-download-cloud:before {
  content: "\e960";
}
.icon-button-droplet:before {
  content: "\e961";
}
.icon-button-edit:before {
  content: "\e962";
}
.icon-button-edit-2:before {
  content: "\e963";
}
.icon-button-edit-3:before {
  content: "\e964";
}
.icon-button-external-link:before {
  content: "\e965";
}
.icon-button-eye:before {
  content: "\e966";
}
.icon-button-eye-off:before {
  content: "\e967";
}
.icon-button-facebook:before {
  content: "\e968";
}
.icon-button-fast-forward:before {
  content: "\e969";
}
.icon-button-feather:before {
  content: "\e96a";
}
.icon-button-figma:before {
  content: "\e96b";
}
.icon-button-file:before {
  content: "\e96c";
}
.icon-button-file-minus:before {
  content: "\e96d";
}
.icon-button-file-plus:before {
  content: "\e96e";
}
.icon-button-file-text:before {
  content: "\e96f";
}
.icon-button-film:before {
  content: "\e970";
}
.icon-button-filter:before {
  content: "\e971";
}
.icon-button-flag:before {
  content: "\e972";
}
.icon-button-folder:before {
  content: "\e973";
}
.icon-button-folder-minus:before {
  content: "\e974";
}
.icon-button-folder-plus:before {
  content: "\e975";
}
.icon-button-framer:before {
  content: "\e976";
}
.icon-button-frown:before {
  content: "\e977";
}
.icon-button-gift:before {
  content: "\e978";
}
.icon-button-git-branch:before {
  content: "\e979";
}
.icon-button-git-commit:before {
  content: "\e97a";
}
.icon-button-git-merge:before {
  content: "\e97b";
}
.icon-button-git-pull-request:before {
  content: "\e97c";
}
.icon-button-github:before {
  content: "\e97d";
}
.icon-button-gitlab:before {
  content: "\e97e";
}
.icon-button-globe:before {
  content: "\e97f";
}
.icon-button-grid:before {
  content: "\e980";
}
.icon-button-hard-drive:before {
  content: "\e981";
}
.icon-button-hash:before {
  content: "\e982";
}
.icon-button-headphones:before {
  content: "\e983";
}
.icon-button-heart:before {
  content: "\e984";
}
.icon-button-help-circle:before {
  content: "\e985";
}
.icon-button-hexagon:before {
  content: "\e986";
}
.icon-button-home:before {
  content: "\e987";
}
.icon-button-image:before {
  content: "\e988";
}
.icon-button-inbox:before {
  content: "\e989";
}
.icon-button-info:before {
  content: "\e98a";
}
.icon-button-instagram:before {
  content: "\e98b";
}
.icon-button-italic:before {
  content: "\e98c";
}
.icon-button-key:before {
  content: "\e98d";
}
.icon-button-layers:before {
  content: "\e98e";
}
.icon-button-layout:before {
  content: "\e98f";
}
.icon-button-life-buoy:before {
  content: "\e990";
}
.icon-button-link:before {
  content: "\e991";
}
.icon-button-link-2:before {
  content: "\e992";
}
.icon-button-linkedin:before {
  content: "\e993";
}
.icon-button-list:before {
  content: "\e994";
}
.icon-button-loader:before {
  content: "\e995";
}
.icon-button-lock:before {
  content: "\e996";
}
.icon-button-log-in:before {
  content: "\e997";
}
.icon-button-log-out:before {
  content: "\e998";
}
.icon-button-mail:before {
  content: "\e999";
}
.icon-button-map:before {
  content: "\e99a";
}
.icon-button-map-pin:before {
  content: "\e99b";
}
.icon-button-maximize:before {
  content: "\e99c";
}
.icon-button-maximize-2:before {
  content: "\e99d";
}
.icon-button-meh:before {
  content: "\e99e";
}
.icon-button-menu:before {
  content: "\e99f";
}
.icon-button-message-circle:before {
  content: "\e9a0";
}
.icon-button-message-square:before {
  content: "\e9a1";
}
.icon-button-mic:before {
  content: "\e9a2";
}
.icon-button-mic-off:before {
  content: "\e9a3";
}
.icon-button-minimize:before {
  content: "\e9a4";
}
.icon-button-minimize-2:before {
  content: "\e9a5";
}
.icon-button-minus:before {
  content: "\e9a6";
}
.icon-button-minus-circle:before {
  content: "\e9a7";
}
.icon-button-minus-square:before {
  content: "\e9a8";
}
.icon-button-monitor:before {
  content: "\e9a9";
}
.icon-button-moon:before {
  content: "\e9aa";
}
.icon-button-more-horizontal:before {
  content: "\e9ab";
}
.icon-button-more-vertical:before {
  content: "\e9ac";
}
.icon-button-mouse-pointer:before {
  content: "\e9ad";
}
.icon-button-move:before {
  content: "\e9ae";
}
.icon-button-music:before {
  content: "\e9af";
}
.icon-button-navigation:before {
  content: "\e9b0";
}
.icon-button-navigation-2:before {
  content: "\e9b1";
}
.icon-button-octagon:before {
  content: "\e9b2";
}
.icon-button-package:before {
  content: "\e9b3";
}
.icon-button-paperclip:before {
  content: "\e9b4";
}
.icon-button-pause:before {
  content: "\e9b5";
}
.icon-button-pause-circle:before {
  content: "\e9b6";
}
.icon-button-pen-tool:before {
  content: "\e9b7";
}
.icon-button-percent:before {
  content: "\e9b8";
}
.icon-button-phone:before {
  content: "\e9b9";
}
.icon-button-phone-call:before {
  content: "\e9ba";
}
.icon-button-phone-forwarded:before {
  content: "\e9bb";
}
.icon-button-phone-incoming:before {
  content: "\e9bc";
}
.icon-button-phone-missed:before {
  content: "\e9bd";
}
.icon-button-phone-off:before {
  content: "\e9be";
}
.icon-button-phone-outgoing:before {
  content: "\e9bf";
}
.icon-button-pie-chart:before {
  content: "\e9c0";
}
.icon-button-play:before {
  content: "\e9c1";
}
.icon-button-play-circle:before {
  content: "\e9c2";
}
.icon-button-plus:before {
  content: "\e9c3";
}
.icon-button-plus-circle:before {
  content: "\e9c4";
}
.icon-button-plus-square:before {
  content: "\e9c5";
}
.icon-button-pocket:before {
  content: "\e9c6";
}
.icon-button-power:before {
  content: "\e9c7";
}
.icon-button-printer:before {
  content: "\e9c8";
}
.icon-button-radio:before {
  content: "\e9c9";
}
.icon-button-refresh-ccw:before {
  content: "\e9ca";
}
.icon-button-refresh-cw:before {
  content: "\e9cb";
}
.icon-button-repeat:before {
  content: "\e9cc";
}
.icon-button-rewind:before {
  content: "\e9cd";
}
.icon-button-rotate-ccw:before {
  content: "\e9ce";
}
.icon-button-rotate-cw:before {
  content: "\e9cf";
}
.icon-button-rss:before {
  content: "\e9d0";
}
.icon-button-save:before {
  content: "\e9d1";
}
.icon-button-scissors:before {
  content: "\e9d2";
}
.icon-button-search:before {
  content: "\e9d3";
}
.icon-button-send:before {
  content: "\e9d4";
}
.icon-button-server:before {
  content: "\e9d5";
}
.icon-button-settings:before {
  content: "\e9d6";
}
.icon-button-share:before {
  content: "\e9d7";
}
.icon-button-share-2:before {
  content: "\e9d8";
}
.icon-button-shield:before {
  content: "\e9d9";
}
.icon-button-shield-off:before {
  content: "\e9da";
}
.icon-button-shopping-bag:before {
  content: "\e9db";
}
.icon-button-shopping-cart:before {
  content: "\e9dc";
}
.icon-button-shuffle:before {
  content: "\e9dd";
}
.icon-button-sidebar:before {
  content: "\e9de";
}
.icon-button-skip-back:before {
  content: "\e9df";
}
.icon-button-skip-forward:before {
  content: "\e9e0";
}
.icon-button-slack:before {
  content: "\e9e1";
}
.icon-button-slash:before {
  content: "\e9e2";
}
.icon-button-sliders:before {
  content: "\e9e3";
}
.icon-button-smartphone:before {
  content: "\e9e4";
}
.icon-button-smile:before {
  content: "\e9e5";
}
.icon-button-speaker:before {
  content: "\e9e6";
}
.icon-button-square:before {
  content: "\e9e7";
}
.icon-button-star:before {
  content: "\e9e8";
}
.icon-button-stop-circle:before {
  content: "\e9e9";
}
.icon-button-sun:before {
  content: "\e9ea";
}
.icon-button-sunrise:before {
  content: "\e9eb";
}
.icon-button-sunset:before {
  content: "\e9ec";
}
.icon-button-tablet:before {
  content: "\e9ed";
}
.icon-button-tag:before {
  content: "\e9ee";
}
.icon-button-target:before {
  content: "\e9ef";
}
.icon-button-terminal:before {
  content: "\e9f0";
}
.icon-button-thermometer:before {
  content: "\e9f1";
}
.icon-button-thumbs-down:before {
  content: "\e9f2";
}
.icon-button-thumbs-up:before {
  content: "\e9f3";
}
.icon-button-toggle-left:before {
  content: "\e9f4";
}
.icon-button-toggle-right:before {
  content: "\e9f5";
}
.icon-button-tool:before {
  content: "\e9f6";
}
.icon-button-trash:before {
  content: "\e9f7";
}
.icon-button-trash-2:before {
  content: "\e9f8";
}
.icon-button-trello:before {
  content: "\e9f9";
}
.icon-button-trending-down:before {
  content: "\e9fa";
}
.icon-button-trending-up:before {
  content: "\e9fb";
}
.icon-button-triangle:before {
  content: "\e9fc";
}
.icon-button-truck:before {
  content: "\e9fd";
}
.icon-button-tv:before {
  content: "\e9fe";
}
.icon-button-twitch:before {
  content: "\e9ff";
}
.icon-button-twitter:before {
  content: "\ea00";
}
.icon-button-type:before {
  content: "\ea01";
}
.icon-button-umbrella:before {
  content: "\ea02";
}
.icon-button-underline:before {
  content: "\ea03";
}
.icon-button-unlock:before {
  content: "\ea04";
}
.icon-button-upload:before {
  content: "\ea05";
}
.icon-button-upload-cloud:before {
  content: "\ea06";
}
.icon-button-user:before {
  content: "\ea07";
}
.icon-button-user-check:before {
  content: "\ea08";
}
.icon-button-user-minus:before {
  content: "\ea09";
}
.icon-button-user-plus:before {
  content: "\ea0a";
}
.icon-button-user-x:before {
  content: "\ea0b";
}
.icon-button-users:before {
  content: "\ea0c";
}
.icon-button-video2:before {
  content: "\ea0d";
}
.icon-button-video2-off:before {
  content: "\ea0e";
}
.icon-button-voicemail:before {
  content: "\ea0f";
}
.icon-button-volume:before {
  content: "\ea10";
}
.icon-button-volume-1:before {
  content: "\ea11";
}
.icon-button-volume-2:before {
  content: "\ea12";
}
.icon-button-volume-x:before {
  content: "\ea13";
}
.icon-button-watch:before {
  content: "\ea14";
}
.icon-button-wifi:before {
  content: "\ea15";
}
.icon-button-wifi-off:before {
  content: "\ea16";
}
.icon-button-wind:before {
  content: "\ea17";
}
.icon-button-x:before {
  content: "\ea18";
}
.icon-button-x-circle:before {
  content: "\ea19";
}
.icon-button-x-octagon:before {
  content: "\ea1a";
}
.icon-button-x-square:before {
  content: "\ea1b";
}
.icon-button-youtube:before {
  content: "\ea1c";
}
.icon-button-zap:before {
  content: "\ea1d";
}
.icon-button-zap-off:before {
  content: "\ea1e";
}
.icon-button-zoom-in:before {
  content: "\ea1f";
}
.icon-button-zoom-out:before {
  content: "\ea20";
}
