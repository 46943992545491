/*******************************************
Header Styles
*******************************************/
//


.header-right-container{
	position: relative;
	display: flex;
    flex-direction: row;
	align-items: center;
	button {
		margin: 0;
		font-size: 12px;
	}
}


.chain-selector {
	padding: 4px 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	background: $color-panel-button;
	border-radius: $radius-sm;
	// box-shadow: $shadow-panel-sub;
	cursor: pointer;
	flex-direction: row;
	max-height: 27px;
	&:hover{
		background: $color-panel-button-roll;
	}
}

.chain-icon{
    max-width: 20px;
    max-height: 20px;
	&.wallet-icon{
		max-width: 27px;
    	max-height: 27px;
	}
	&.small{
		max-width: 16px;
    	max-height: 16px;
	}
	&.large{
		width: 27px;
		height: 27px;
		max-width: 44px !important;
		max-height: 44px !important;
	}
	margin-right: 4px;
}

.chain-panel{
	position: absolute;
    right: 0;
    top: $main-header-height;
    width: 220px;
    // height: 180px;
    // background: $color-panel-sub;
	box-shadow: $shadow-panel-sub;
	// border-radius: 0 0 0 5px;
	border-bottom-left-radius: 7px;
	overflow: hidden;
	transform: translateY(calc(-100% - $main-header-height - 10px));
	background: rgb(20 21 22 / 37%);

	max-height: 80%;

	overflow-y: auto;

	backdrop-filter: blur(3px);

	-webkit-transition: transform $drawer-duration;
    -moz-transition: transform $drawer-duration;
    -o-transition: transform $drawer-duration;
	transition: transform $drawer-duration;

	z-index: 777;
	&.show{
		transform: translateY(0);
	}
	.chain-item:first-child{
		border: 0 !important;
	}
}

.chain-item{
	width: 100%;
	&.col-2{
		width: 50%;
	}
	border-bottom:1px solid #202020;
	border-right:1px solid #202020;

    display: flex;
    flex-direction: row;
    padding: 10px;

	padding: 5px 10px;
	// background: $color-panel-sub-item-dark;
	// background: rgba(16, 24, 27, 0.88);
	
	// background: linear-gradient(rgb(23 23 23), rgb(19 19 19));
	// background: linear-gradient(rgb(20 24 26), rgb(16 20 23));
	
	border-top: $color-panel-sub-item-border;
	align-items: center;
	cursor: pointer;
	position: relative;
	&:hover{
		background: $color-panel-button-roll;
		background: #101112ee;
		.data-label{
			display: inline !important;
		}
	}
	&.active{
		// background: $color-panel-sub-item-active;
		background: #101112ee;
		cursor: default;
	}
	p{
		margin: 0;
		margin-left: 5px;
	    line-height: initial;
	    font-size: 12px;
		font-weight: 600;
	}
}

.wallet-button {
	&:hover{
		.active-green-circle{
			background: $color-green-neon;
		}
	}
}


// neon button


// :root {
//   --clr-neon: hsl(317 100% 54%);
//   --clr-neon: hsl(209deg 100% 54%);
//   --clr-bg: hsl(323 21% 16%);
// }
//
//
// .neon-button {
//   // font-size: 4rem;
//
//   display: inline-block;
//   cursor: pointer;
//   text-decoration: none;
//   color: var(--clr-neon);
//   border: var(--clr-neon) 0.125em solid;
//   padding: 0.25em 1em;
//   border-radius: 0.25em;
//
//   text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;
//
//   box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
//
//   position: relative;
// }
//
// .neon-button::before {
//   pointer-events: none;
//   content: "";
//   position: absolute;
//   background: var(--clr-neon);
//   top: 120%;
//   left: 0;
//   width: 100%;
//   height: 100%;
//
//   transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
//   filter: blur(1em);
//   opacity: 0.7;
// }
//
// .neon-button::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   box-shadow: 0 0 2em 0.5em var(--clr-neon);
//   opacity: 0;
//   background-color: var(--clr-neon);
//   z-index: -1;
//   transition: opacity 100ms linear;
// }
//
// .neon-button:hover,
// .neon-button:focus {
//   color: var(--clr-bg);
//   text-shadow: none;
// }
//
// .neon-button:hover::before,
// .neon-button:focus::before {
//   opacity: 1;
// }
// .neon-button:hover::after,
// .neon-button:focus::after {
//   opacity: 1;
// }
