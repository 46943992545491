/************************************************************
Responsive mixins
************************************************************/

$break-xxs: 350px;
$break-xs: 480px;
$break-sm: 640px;
$break-med: 768px;
$break-lrg: 1024px;
$break-xlrg: 1120px;
$break-big: 1400px;
$break-realbig: 1700px;

@mixin break-min($point) {
    @media screen and (min-width: $point) {
        @content;
    }
}

@mixin break-max($point) {
    @media screen and (max-width: $point) {
        @content;
    }
}

@mixin break-min-height($point) {
    @media screen and (min-height: $point) {
        @content;
    }
}

@mixin break-max-height($point) {
    @media screen and (max-height: $point) {
        @content;
    }
}

@mixin orientation($orientation) {
    @media only screen and (orientation: $orientation) {
        @content;
    }
}

// Bootstrap method for showing elements above define size

.show-xs,
.show-sm,
.show-med,
.show-lrg,
.show-xlrg {
    display: none;
}

.show-xs {
    @include break-min($break-xs) {
        display: block;
    }
}
.show-sm {
    @include break-min($break-sm) {
        display: block;
    }
}
.show-med {
    @include break-min($break-med) {
        display: block;
    }
}
.show-lrg {
    @include break-min($break-lrg) {
        display: block;
    }
}
.show-xlrg {
    @include break-min($break-xlrg) {
        display: block;
    }
}
