/*******************************************
General Styles and Classes
*******************************************/

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Saira:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Finlandica:ital,wght@0,400;0,500;0,600;0,700;&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');


:root {
    --rt-color-white: #fff;
    --rt-color-dark: #0d3753;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
    --rt-transition-show-delay: 0.15s;
    --rt-transition-closing-delay: 0.15s;
}

html, body {
    // font-family: "Saira", "Montserrat","Source Sans Pro", "Helvetica", sans-serif !important;
    font-family: "Montserrat","Source Sans Pro", "Helvetica", sans-serif !important;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    touch-action: none;
    min-height: -webkit-fill-available;
}

body.inApp{
    font-family: "Roboto Mono", "Montserrat","Source Sans Pro", "Helvetica", sans-serif !important;
    // font-family: "Droid Sans Mono",Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
    // font-size: .9em;
    // font-family: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;
}


%mono,
.mono {
    // font-family: "PT Mono", monospace;
    font-family: "Courier New", Courier, monospace;
}

html {
    color: $color-text;
    // font-family: "Saira", "Montserrat","Source Sans Pro", "Helvetica", sans-serif;
    // font-family: "Montserrat","Source Sans Pro", "Helvetica", sans-serif;
    font-size: 15px;
    @include break-max($break-xs) {
        // font-size: 1.4em;
    }

    &:not(.no-responsive){
        *{
            @include break-max($break-med) {
                // font-size: 13px;
            }
        }
    }
    
    
    
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    // -webkit-tap-highlight-color: transparent;
    // font-feature-settings: 'ss01' on,'ss02' on,'cv01' on,'cv03' on;
}

body {

    // background-image: linear-gradient(352deg, #541965 0%, #8f8f8f 74%);
    // background-image: linear-gradient(315deg, rgba(1,28,47,1) 0%, rgba(28,148,206,1) 99%) !important;
    // background-image: linear-gradient(315deg, rgb(1, 28, 47) 0%, rgb(20 96 133) 99%) !important;
    color: inherit;
    font-family: inherit;
    // height: 100%;
    margin: 0;

    &.noscroll {
        overflow: hidden;
    }
}


#root {
    // background: #1e1e1e;
    // background: repeating-linear-gradient(135deg, #0c1d2399, #0b0f1b99 7px);

    // background: repeating-linear-gradient(135deg, rgb(12 29 35 / 22%), rgb(11 15 27 / 33%) 7px);

    // background-image: url(/media/textures/dropbar3.png);
    // background-repeat: repeat;
    // background-image: url(/media/textures/micro_grid.png);
    // background-repeat: repeat;
    // position: relative;
}

#root:before {
    opacity:1;

}

*:focus {
  outline: none;
}

/* Add back the outline for keyboard navigation */
*:focus:not(.keyboard-navigation) {
  outline: none;
}

.tablet-off{
    @include break-max($break-sm) {
        
        display: none !important;
        
    }
}

.mobile-off{
    @include break-max($break-xs) {
        
        display: none !important;
    
    }
}


label,
.meta,
%meta {
    color: $color-text-light;
    font-size: 13px;
    font-weight: 600;
    &.large {
        font-size: 16px;
    }

    &.hidden {
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        position: absolute;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 11px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    color: #bac8ca;
}

canvas {
    cursor: inherit; /* Ensure it's not set to a specific value globally */
}

h1 {
    // font-size: 36px;
    // font-weight: $light;
    // letter-spacing: -0.5px;
    font-size: 37px;
    // font-weight: 600;
    letter-spacing: -0.5px;
    color: #d1d3d5;
}

h2 {
    font-size: 22px;
    letter-spacing: -0.4px;
    line-height: 27px;
    font-weight: 600;

    @include break-max($break-sm) {
		font-size: 20px;
	}

    @include break-max($break-xs) {
		font-size: 15px;
	}
    // line-height: 32px;
}

h3 {
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 27px;
}

h4 {
    font-size: 21px;
    font-weight: $semi-bold;
    letter-spacing: -0.2px;
    color: #dddddd;
    @include break-max($break-sm) {
		font-size: 17px;
	}

    @include break-max($break-xs) {
		font-size: 15px;
	}
    // line-height: 24px;
}

h5 {
    font-size: 18px;
    font-weight: $semi-bold;
    letter-spacing: -0.1px;
    // line-height: 24px;
}

h6 {
    font-size: 14px;
    font-weight: $bold;
    letter-spacing: -0.05px;
    @include break-max($break-sm) {
		font-size: 13px;
	}

    @include break-max($break-xs) {
		font-size: 12px;
	}
    // line-height: 24px;
}

a {
    cursor: pointer;
    // color: $color-link;
    color: $color-text;

    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

p {
    font-size: 13px;
    line-height: 19px;
    margin: 0 0 20px;

    &.small {
        font-size: 14px;
        line-height: 21px;
        margin: 0 0 15px;

    }

    &.large {
        font-size: 21px;
        line-height: 32px;
    }

    + ul {
        margin-top: -10px;
    }

    &.positive{
        color: $color-positive;
    }


    .positive{
        color: $color-positive;
    }

}

code {
    @extend %mono;
}

ul {
    margin: 0 0 20px;
    padding: 0 15px;

    li {
        margin: 0 0 12px;
    }
}

hr {
    margin: 30px 0;
    border: none;
    border-bottom: 1px dashed #E1E4E6;
    // border-color: rgb(53 190 244 / 30%);
    border-color: #21526680;

}

// Global Classes

.art-logo {

    a {
        display: block;
    }

    img {
        display: block;
        height: 20px;
        width: auto;
    }
}

.avatar {
    border-radius: 50%;
    display: block;
    width: 64px;
    height: 64px;

    &.medium {
        width: 82px;
        height: 82px;
    }

    &.large {
        width: 100px;
        height: 100px;
    }
}

// Color states

.error,
.danger {
    color: $color-danger !important;

    &:hover {
        color: $color-danger !important;
    }
}

.success {
    color: $color-green;
}

.delete {
    color: $color-red;
}

.warn {
    color: $color-warn;
}

.copied {
    color: $color-blue-lightest;
    font-size: 12px;
    margin-left: 7px;
    font-weight: 600;
}

.green-circle {
    width: 7px;
    height: 7px;
    &.large{
        width:11px;
        height:11px;
    }
    background-color: green;
    border-radius: 50%;
    &.animated{
        animation: fade 2.5s infinite;
    }
   
    &.delay{
        animation-delay: 1.25s;
    }
}

@keyframes fade {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.53;
    }
}

// Loader

.loader-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    z-index: 0;
}

.spinner {
    display: block;
    height: inherit;
    width: inherit;
    filter: invert(1) brightness(0.5);
    opacity:.5;

    &.small {
        width: 20px;
        height: 20px;
    }
}




/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #272727;
}
::-webkit-scrollbar-corner{
    background: #272727;
}


/* Handle */
::-webkit-scrollbar-thumb {
  // background: rgb(77,77,77);
  background: rgba(55,75,85,.5);
  border-radius: 4px;
  min-height: 100px;
  // background: #5a6466;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-brand;
}


.loader-spinner{
    z-index: 99999;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 44px;
    height: 44px;
    // background: $color-panel-sub;
    align-items: center;
    border-radius: 7px;
}

.loader-spinner-element {

    // margin:100px auto;
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;

    justify-content: center;
    // perspective-origin: 22px 22px;
    // transform-origin: 22px 22px;
    border: 0px solid rgb(0, 0, 0);
    flex: 0 0 auto;
    flex-flow: column nowrap;
    position: absolute;

    &:after,
    &:before {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        border-style: solid;
        border-width: 3px;
        box-sizing: border-box;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
    }
    &:after {
        -webkit-animation: rotate 1s infinite ease;
        animation: rotate 1s infinite ease;
        border-color: $color-brand transparent transparent;
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
    }
    &:before {
        border-color: rgba(100,100,100,.5);
    }
    a {
        display: block;
        font-size: 14px;
        margin: -60px 0;
        padding: 60px 9px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        z-index: 1;
        color: rgb(199, 199, 199);
        text-decoration: none;
        font: normal normal 600 normal 14px / 14px proxima-nova, "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.transition{
    -webkit-transition: all $duration-notification;
    -moz-transition: all $duration-notification;
    -o-transition: all $duration-notification;
    transition: all $duration-notification;
}

.transition-long{
    -webkit-transition: all $duration-transition-long;
    -moz-transition: all $duration-transition-long;
    -o-transition: all $duration-transition-long;
    transition: all $duration-transition-long;
}

.first-capital{
    text-transform: capitalize;
}

//
// .note{
//   color: rgb(199, 199, 199);
//   text-decoration: none;
//   font: normal normal 600 normal 12px / 12px proxima-nova, "Helvetica Neue", Arial, Helvetica, sans-serif;
//   text-align:center;
//   width:100%;
// }


@supports (-webkit-touch-callout: none) {
    body {
      min-height: 100vh;
      min-height: -webkit-fill-available; /* Fixes Safari's viewport issue */
    }
  
    .main-header,
    .main-footer {
      height: env(safe-area-inset-top, 60px); /* Handles safe area padding for iPhones with notches */
    }
  }