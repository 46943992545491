/*******************************************
Header Styles
*******************************************/

@mixin main-menu-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-weight: $semi-bold;
    color: $color-main-menu-btn;
    // transition: $hover-duration all;
    position: relative;

    &.active,
    &:hover {
        color: white;
        text-decoration: none;
    }

    > i {
        font-size: 22px;
        line-height: 22px;
        margin-right: 10px;
    }
}


.home, .lobby{
    .sidebar{
        // display: none !important;
    }
}


.sidebar {
    // background: $color-panel-sub-opaque-dark;
    background: rgb(12 16 19 / 44%);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    
    scrollbar-width: "none"; 
    -ms-overflow-style: "none";
    &::-webkit-scrollbar {
        display: none;
    }
   
    // display: none;
    .select{
        select{
            min-width: 120px;
        }
        // width: 60%;
    }

    .coin-link{
        aspect-ratio: 1;
        min-width: 36px;
        min-height: 36px;
    }

    // padding: 60px 20px 10px 20px;
    padding: 44px 0 20px 0;
    // box-shadow: $shadow-panel;

    
    z-index: 2;
    // transition: all $hover-duration;
    // box-shadow: 0 0 4px 3px rgba(0,0,0,.3);
    overflow-x: hidden;
    // position: absolute;
    height: calc( 100vh - $main-header-height );
    position: relative;

    @include break-max($break-sm) {
       
        height: calc( 100vh - $main-header-height - $main-footer-height );
    }

    @include break-max($break-xs) {
        &.hide{
            display: none;
        }
        width: 100% !important;
    }

    &.left{
        width: $sidebar-width-left;
        background: rgb(8 10 11 / 82%);
        border-right: 1px solid #0b101a22;
        &.hide{
            width: 35px;
        }
    }
    &.content{
        width: $sidebar-width-content;
        border-right: 1px solid #0b101a22;
        padding-top: 0;
        &.hide{
            width: 35px;
        }

    }
    &.right{
        width: $sidebar-width-right;
        // border-left: $border-section;
        padding: 0;
        // background: rgb(37 37 37 / 99%);

        // background-image: linear-gradient(315deg, rgb(20 21 21) 0%, rgb(14 14 15) 74%);
        // background-image: linear-gradient(315deg, rgb(25 47 59) 0%, rgb(23 41 55) 74%);
        // background: #1d1d1d;
        @include break-max($break-sm) {

            width: 100% !important;
        }

        &.hide{
            width: 0 !important;
        }
    }


    &.hide{
        // left: -$sidebar-width;
        // transform: translateX(-100%);
        box-shadow: none;
        .icon-action-button.top-right {
            transform: scale(.9);
            right: 1px;
        }
        // width: 0 !important;
    }




    // &::after {
    //     background: rgba($color-background-dark, 0.80);
    //     backdrop-filter: blur(5px);
    //     content: '';
    //     position: fixed;
    //     width: $sidebar-width;
    //     height: 80px;
    //     top: 0;
    //     left: 0;
    //     z-index: 2;
    // }

    .data-row{
        align-items: center;
        width: 100%;
        border-radius: 0;
        max-width: 100%;
        // overflow: hidden;
    }

    .main-menu {
        // margin-top: 44px;
        position: relative;



        .main-menu-btn {

            &.disabled{
                pointer-events: none;
            }
            background-color: transparent;
            // border-radius: $radius-lg;
            @include main-menu-btn();
            // margin: 0 -6px 10px;
            margin: 0;
            border-top: 1px solid rgba($color-frost, 0.11);;
            padding: 12px 11px;
            max-height: 33px;
            color: $color-text;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &.no-space{
                // justify-content: flex-start;
                font-size: 12px;
            }

            [class^=icon-button], [class*=" icon-button"]{
                margin-right:11px;
            }


            .svg-icon{
                color: $color-text;
                width: 16px;
                height: 16px;
                margin-right: 9px;
                margin-left: -1px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.large{
                    svg{
                        max-height: 15px;
                    }
                }
                &.desaturate{
                    svg{
                        filter: saturate(0);
                    }
                    
                }

                &.no-fill{
                    svg{
                        fill: none;
                        path{
                            fill:none;
                        }
                    }
                }
            }

            svg{
                stroke: $color-text;
                stroke: transparent !important;
                fill: $color-text;
                width: 100%;
                max-height: 11px;
            }
            .main-menu-title{
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 12px;
            }

            &.active,
            &:hover {
                background-color: rgba(45, 72, 88, .44);
                
                .color-box{
                    opacity: 1;
                }
            }

            &.active i, &.active [class^=icon-button], &.active [class*=" icon-button"]{
                color: $color-brand-blue;
            }
            &.active svg{
                filter: saturate(1) !important;
                fill: $color-brand-blue;
                color: $color-brand-blue;
                path{
                    fill: $color-brand-blue;
                }
            }

            &:hover i, &:hover [class^=icon-button], &:hover [class*=" icon-button"]{
                color: $color-brand-blue;
            }
            &:hover svg{
                filter: saturate(1) !important;
                fill: $color-brand-blue;
                color: $color-brand-blue;
                path{
                    fill: $color-brand-blue;
                }
                // stroke: $color-button-action !important;
                stroke: $color-brand-blue;
            }
        }

        .main-menu-btn:first-child{
            border: none;
            // font-size: 15px;
        }

        hr {
            border-color: rgba($color-frost, 0.15);
            margin: 32px 0;
        }
    }

    .account-btn-wrapper {
        margin-top: auto;
        margin-bottom: 20px;
        padding-top: 60px;

        .account-btn {
            @include main-menu-btn();
            display: block;
            text-align: center;

            .avatar {
                margin: 0 auto 15px;
                transform: scale(1);
                transition: $hover-duration transform;
            }

            &:hover .avatar {
                transform: scale(1.1);
            }

            &.active .avatar {
                box-shadow: 0 0 0 2px $color-background-dark, 0 0 0 4px $color-brand-blue;
            }
        }
    }
}
