/*******************************************
Collection Styles
*******************************************/

.collections-header{
	width:100%;
	margin-top: 53px;
}
.collections-container{
	width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 10px;
    // height: 100%;

	width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 10px;
    /* height: 100%; */
    overflow: auto;
    justify-content: flex-start;
    align-items: flex-start;

}

.collection-item{
	flex-basis: 44%;
  	flex-shrink: 0;
	max-width: 540px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 30%);
    overflow: hidden;
    padding: 15px;
    margin: 10px;
    min-height: 144px;
    position: relative;
    cursor: pointer;
	background-image: url(/media/textures/dropbar3.png);
	background-repeat: repeat-x;

	width:44%;
	@include break-max($break-xs) {
	   flex-basis: 98%;
	   margin: 1%;
	}
	@include break-min($break-lrg) {
		flex-basis: 30%;
		margin: 10px;
	}
    aspect-ratio: 3;

	&:hover{
		.collection-color-panel{
			opacity: 0;
			background: $color-panel-sub !important;
		}
		.collection-background-images{
			filter: saturate(1);
		}
	}

	// filter: url('#cherry-icecream') contrast(2);
	// filter: hue-rotate(90deg) contrast(1.4);

	.collection-content{
		z-index: 3;
	    position: absolute;
	    display: flex;
	    flex-direction: row;
	    bottom: 15px;
		align-items: center;
		padding: 7px 10px;
		background: $color-panel-sub;
		border-radius: $radius-md;
		box-shadow: $shadow-tight;
		h6{
			margin: 0;
		}

		@include break-max($break-sm) {
	       h6{
			   font-size: 13px;
		   }
	    }
	}

	.collection-color-panel{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		opacity: .5;
		background-image: url(/media/textures/dropbar3.png);
		background-repeat: repeat-x;
	}

	.collection-background-images{
		position: absolute;
	    width: 100%;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    z-index: -1;
		display: flex;
		flex-direction: row;
		filter: saturate(0);
		.collection-background-image{
			flex: 1;
			background-repeat: no-repeat;
		    background-size: cover;
		    background-position: center;
		}
	}
}

.qr-code-panel{

	position: absolute;
    bottom: 46px;
    right: 25px;
    width: 144px;
    height: 144px;
    padding: 10px;
	background: $color-panel-sub;
	background-image: url(/media/textures/dropbar3.png);
	background-repeat: repeat-x;
    z-index: 999;
    border-radius: 7px;
	svg{
		max-width: 100%;
		max-height: 100%;
	}
}

.collections-options{
	position: absolute;
	z-index: 9999;
	transform: translateX(-50%);
	.option{
		max-height: 38px;
		min-height: 38px;
	}
}

.options-menu{

	background-color: $color-panel-sub-full;
	// background-image: url(/media/textures/dropbar3.png);
	background-repeat: repeat-x;
	padding: 0 0 11px 0;
	z-index: 144;
	min-width: 188px;
	border-radius: $radius-md;
	box-shadow: $shadow-tight;
	position: absolute;
	border: 1px solid #212121;
	cursor: default;

	.options-header{
		border-radius: $radius-md;
		pointer-events: none;
		display: flex;
		flex-direction: row;
		font-size: 11px;
		font-weight: 600;
		align-items: center;
		padding: 9px;
		background-image: url(/media/textures/dropbar3.png);
        background-repeat: repeat-x;
		i{

		}
	}

	.option{
		cursor:pointer;
		&:hover{
			background: $color-panel-button-roll;
			.count-circle{
				opacity: 0;;
			}
			.plus-sign{

				opacity: 1;
			}
		}
		&.disabled{
			pointer-events: none;
			filter: saturate(0);
			opacity: .5;
		}
		background: $color-panel-button;
		padding: 7px;
	    font-size: 11px;
	    font-weight: bold;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		border-top: 1px solid $color-border-dark;
		.svg-icon {
		    width: 16px;
		    margin: 2px;
		    margin-right: 8px;
		}
		p{
			word-break: break-word;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 144px;
			margin: 0;
			font-size: 11px !important;
			font-weight: 600 !important;
		}

		&:first-child{
			border-top: none;
		}


		.plus-sign{
			position: absolute;
			right:11px;
			top:50%;
			margin-top: -5px;
			opacity: 0;
		}
	}

	.arrow-up,.arrow-down{
		left:50%;
		margin-left: -5px;
	}

	.arrow-up {
		position: absolute;
		top: -5px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid rgba(48, 51, 54, 0.9);
		display: none;
	}

	.arrow-down {
	  position: absolute;
  	  bottom: -5px;
	  width: 0;
	  height: 0;
	  border-left: 5px solid transparent;
	  border-right: 5px solid transparent;
	  border-top: 5px solid $color-panel-light;
	  display: none;
	}

	&.arrowtop{
		.arrow-up{
			display: block;
		}
	}
	&.arrowbottom{
		.arrow-down{
			display: block;
		}
	}
}


.color-box{
	width: 8px;
	height: 8px;
	border-radius: $radius-sm;
	border: 1px solid;
	opacity: .8;
	margin-left:2px;
}


.carousel-root {
	outline: none;
	max-height: calc( 100vh - $main-header-height );
	display: flex;
	flex-direction: column;
	.carousel .slider-wrapper.axis-horizontal .slider .slide {
	    flex-direction: column;
	    flex-flow: column;
	    display: flex;
	    align-items: center;
	}
	.carousel.carousel-slider {
		width: 100%;
		display: flex;
		height: 77vh;
    	overflow: initial;
		@include break-max($break-sm) {
	       height: 72vh;
	    }
	}


	.carousel .thumbs{
		margin: auto;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}

	.carousel .thumb{
		border: none;
		border-radius: $radius-sm;
		background: $color-panel-button;
		cursor: pointer;
		transition: none;
		img{
			border-radius: $radius-sm;
			// -webkit-transition: transform $duration-notification;
		    // -moz-transition: transform $duration-notification;
		    // -o-transition: transform $duration-notification;
		    // transition: transform $duration-notification;
		}
	}

	.carousel .thumb.selected, .carousel .thumb:hover {


	}
	.carousel .thumb.selected {
		border: 2px solid $color-brand;
		img{
			transform: scale(1.05);
		}
	}
	.carousel .thumb:hover {

		img{
			transform: scale(1.05);
		}
	}

	.carousel .control-dots{
		bottom: -37px;
	}

	.carousel .control-dots .dot {
		background: $color-brand;
	}

	.carousel.carousel-slider .control-arrow {
	    top: initial;
	    color: $color-brand;
		background: $color-panel-button;

		padding: 10px;
	}

	.carousel .control-next.control-arrow {
		right: initial;
	    left: 50%;
	    margin-left: 11px;
	    bottom: 11px;
	}

	.carousel .control-prev.control-arrow {
		left: 50%;
		margin-left: -48px;
    	bottom: 10px;
	}
	.carousel .thumbs-wrapper {
	    margin-top: 44px;
	    overflow: hidden;
	}
	.carousel .carousel-status {
		display: none;
	}
}

.react-simple-star-rating{
	.empty-icons{
		color: $color-white !important;
		opacity: .3 !important;
	}
	.filled-icons{
		color: $color-brand-blue !important;
	}
}
