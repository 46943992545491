/*******************************************
Global Mixins and Keyframes
*******************************************/

// Mixins

@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin container-raised() {
    background: $color-item-dark;
    border: 1px solid $color-border;
    border-radius: $radius-xl;
    box-shadow: 0 2px 6px -3px rgba(0,0,0,0.10);
}

// Keyframes

@keyframes show-form {
    0% {
        opacity: 0;
        transform: scale(.92);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes show-publish {
    0% {
        height: 0;
    }

    100% {
        height: $show-publish-alert;
    }
}
