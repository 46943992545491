/*******************************************
Page Layout
*******************************************/

.full-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    &.profile{
        .page-wrapper{
            overflow-y: auto;
        }
    }
}

.sub-header{
    background-color: $color-panel-sub;
    backdrop-filter: blur(12px);
    height: 33px;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:104px;
    z-index: 11;
    border-radius: 0 0 $radius-md $radius-md;
    .svg-icon{
        svg{
            color: $color-icon;
            fill: $color-icon;
            stroke: $color-icon;
        }

        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // width: 100%;
    // width: calc( 100% - 38px );
}

.section-header, .page-header{
    height: 44px;
    background-color: $color-panel-sub !important;
}

.launch-app{
    display: none;
}

.home, .lobby{
    // .main-header{
    //     display: none !important;
    // }
    .dex-search-link{
        // display: none !important;
    }
    .header-right-container{
        // display: none !important;
    }
    .launch-app{
        display: block;
        background: #172c33;
        &:hover{
            background: #0f4658;
        }
    }
    .main-wrapper{
        height:100vh;
    }
}

.main-header,.main-footer{
    background-color:rgb(33 37 41 / 100%);
    // backdrop-filter: blur(12px);
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
}

.main-footer{
    display:none;
    @include break-max($break-sm) {
        display: block;
    }
}

.inApp{
    .main-header,.main-footer{
        position: relative;
    }
    .main-header{
        display: flex;
        
        transform: translateY(0);
        @include break-max($break-sm) {
            // height: 73px;
        }

        .small{
            opacity:.64;
        }
    }
    .launch-app{
        display: none;
    }
}



.main-header{

    
    padding: 0 6px 0 14px; 
    position: absolute;
    transform: translateY(-55px);

    &.show{
        display: flex;
        transform: translateY(0);
    }
    
    z-index: 888;
   
    .art-logo {
        z-index: 3;
        // margin-right: 20px;
    }
    .menu-hamburger{
        cursor: pointer;
        display: none;
        @include break-max($break-sm) {
            display: block;
        }
        .icon-hamburger{
            font-size: 24px;
            font-weight: 600;
            margin-left: 11px;
            margin-right: 7px;
        }
    }
    .account-btn-wrapper{
        display: flex;
        flex-direction: row;
        .account-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 600;
            text-decoration: none;
            background: $color-background-dark;
            color: $color-grey01;
            padding: 5px;
            border-radius: 5px;

            img{
                width: 26px;
                height: 26px;
                margin-right: 7px;
            }
            &:hover{
                background: $color-grey-dark;
                color: $color-blue-light;
            }
        }
    }
}

.fullscreen .main-header{
    background: none;
    backdrop-filter: none;
    position: fixed;
    z-index: 1;
    display: none;
}

// .main-footer{
//     width: 100vw;
//     height: $main-footer-height;
//     z-index: 99;
//     background: rgba(35, 46, 51, 0.5);
//     padding-left: 13px;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     p{
//         margin: 0;
//         font-size: 10px;
//         opacity: .4;
//     }
// }


.inApp .page-wrapper{
    display: none;
    &.show{
        display: flex;
    }
}

.inWebsite .main-wrapper{
    .page-wrapper{
        align-items: center;
    }
}


.main-wrapper {

    width: 100%;
    height: calc( 100vh - $main-header-height );
    display: flex;
    overflow: hidden;

    @include break-max($break-sm) {
       
        height: calc( 100vh - $main-header-height - $main-footer-height );
    }
    

    .page-wrapper {
        background: rgba(11,11,11,.22);
        // background: $color-panel-dark;
        position: relative;
        z-index: 1;
        flex: 1;
        margin:0;
        overflow-y: auto;
        overflow-x: hidden;
        // display: flex;
        flex-direction: column;
        transition: all $hover-duration;

        .internal-page-padding{
            padding: 60px 70px;

        }

        &.center{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .page-header {
            padding: 0 11px 0 0;
            display: flex;
            justify-content: space-between;
            /* align-items: center; */
            /* margin-bottom: 30px; */
            align-items: center;
            width: 100%;

            h1 {
                margin: 0;
            }

            button {
                margin: 0;
            }
        }

        .page-content-wrapper{
            // min-height: calc(100vh - 100px );
            display: none;
            &.visible{
                display: flex;
            }
            position: relative;
            overflow: auto;
            max-width: 100%;
            scrollbar-gutter: #222;
            // height: 100dvh;
            // padding: 11px;

        }
    }

    &.no-sidebar{
        .page-wrapper{
            // width: 100vw !important;
            // transform: translateX(-$sidebar-width);
        }
    }
}

.inApp .page-wrapper{
    overflow-y: hidden;

}

.fullscreen .main-wrapper{
    height: 100%;
}


.full-panel-wrapper{
    width: 100%;
    margin: 0;
    // padding: 25px;
    background: #131313;
    // max-width: 320px;
    // &:last-child{
    //     margin-left: 5px;
    // }
    .panel-row{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 33px;
        margin-bottom: 44px;
    }

    .full-panel-internal-wrapper{

    }
}

.full-panel-internal-wrapper{
	position: relative;
}

.Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // background: #000;
    // background: rgba(255, 255, 255, .4);
    // opacity: 0.2;
    z-index: 1;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    // min-height: 12px;
    height:4px;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 8px;
    margin: -5px 0;
    border-top: 4px solid rgba(255, 255, 255, .4);
    border-bottom: 4px solid rgba(255, 255, 255, .4);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    // border-top: 2px solid rgba(255, 255, 255, 1);
    // border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.page-tab-container{
    padding: 3px 3px 0 3px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.page-tab{
    font-size: 13px;
    font-weight: 600;
    padding: 5px;
    padding-left: 8px;
    padding-right: 33px;
    background: #2b343d;
    min-width: 120px;
    cursor: pointer;
    border-top-left-radius: $radius-md;
    border-top-right-radius: $radius-md;
    margin-left: 2px;
    position: relative;
    &.active{
        background: $color-panel-sub;
        cursor: default;
    }
    .icon-action-button{
        padding: 2px;
        width: 14px;
        height: 14px;
        right: 8px;
        top: 50%;
        margin-top: -5px;
    }
}

.resize-container {
    width: 100%;
    height: calc( 100vh - 44px );
    display: flex;
}
  
  .resize-column {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .resizer {
    width: 5px;
    background-color: #ccc;
    cursor: ew-resize;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .add-button {
    margin: 10px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  