/*******************************************
Website Styles
*******************************************/


.inWebsite{
	color:#c6c6c6;
}

.content-panel,.text-panel, .dark-panel{
	width: 100%;
	position: relative;
	h6{
		// color: #424242;
		margin-bottom: 5px;
		// font-size: 12px;
	}

	h4{

	}
}

.dark-panel{
	// background: $color-panel-dark;
	background: rgba(17, 17, 17,.64);
	padding:50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include break-max($break-med) {
		padding:33px;
	}
	@include break-max($break-sm) {
		padding:22px;
	}
}

.footer-panel{
	// background: $color-panel-dark;
	// background: #1d1d1d;
	padding: 0;
	display: flex;
	max-height: 492px;
	overflow: hidden;
	position: relative;
	// min-height: 444px;
    // align-items: center;
	// border-top: 3px solid #111111;
	.footer-wrapper{
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		padding: 44px 44px 88px 44px;
		min-height: 486px;
	}
	.coin-links{
		flex:1;
		// justify-content: center;
		max-width: 220px;
	}

	.coin-link {
	    aspect-ratio: 1;
	    min-width: 36px;
	    min-height: 36px;
	}
	.copyright{
		position: absolute;
		bottom:0;
		left:0;
		width: 100%;
		text-align: left;
		background: #0f0f0f;
		padding:5px 11px;
		z-index: 2;
		p{
			text-align: left !important;
			opacity: .77;
		}
	}

	p{
		margin: 0 auto;
		flex:1;
		align-items: center;
		text-align: center;
		&:first-child{
			text-align: left;
		}
		&:last-child{
			text-align: right;
		}
	}
	.footer-image-container{
		position: absolute;
		width: 100%;
		// height: 100%;
		overflow: hidden;
		z-index: -1;
		height: 486px;
		// pointer-events: none;

		.footer-image{
			// background-image: url(/media/photos/wallowas.jpg);
			position: absolute;

			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
			width: calc( 20px + 100% );
			height: calc( 484px );
			transition: transform .33s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			// filter: brightness(0.9);
			filter: saturate(0) brightness(0.55);
			background-size: cover;
			background-position: center;
		}
	}
}

.text-panel{

	.slide-in-text{
		.svg-icon svg path, [class^=icon-button], [class*=" icon-button"] {
		    fill: #1c84cc !important;
			color: #1c84cc !important;
		}
	}

	.data-panel{
		&.interactive{
			box-shadow: 0px 0px 2px;
			&:hover{
				box-shadow: 0px 0px 4px;
			}
		}
	}

	a{
		color:#ddd;
	}




	background: $color-panel-dark;
	// padding: 5px 17px;
	// background: rgb(0 0 0 / 67%);

	&.light{
		// background: $color-panel-main;
		// background: $color-panel-dark !important;
		// background: #FFF;
		background: #c4c4c4;
		background: #090909c9;
		p{
			// color: #1d1d1d;
		}
		h4,h2{
			color:#d0d0d0;
		}
	}

	&.dark{
		//

		&:not(.gradient){
			background: $color-panel-dark !important;
		}
		&.gradient{
			background-image: linear-gradient(315deg, rgb(12, 15, 17) 0%, rgb(28 28 28) 88%);
		}

		p{
			// color: #1d1d1d;
		}
		h4{
			// color:#444;
		}
	}


	.token-copy-container{
		// transform: scale(1.3);
		@include break-max($break-sm) {
			transform: scale(.9);
		}
	}

	padding: 22px 33px;

	@include break-min($break-big) {
		padding: 22px 16%;
	}

	@include break-min($break-realbig) {
		padding: 22px 20%;
	}

	display: flex;
	flex-direction: column;
	align-items: center;


	@include break-max($break-med) {
		padding: 0;
	}

	@include break-max($break-sm) {
		.flex-row{

			&.caption{
				flex-direction: column;
				align-items: flex-start;
			}



		}

		h2{
			margin:0;
		}


		padding: 0;

	}


	p{
		font-weight: 500;
		max-width: 80%;

		@include break-min($break-med) {
			max-width: 620px;
		}
    	text-align: left;
	}
	h4{
		text-align: center;
		font: 12px;

	}

}

.uni-logo{
	max-height: 64px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;
	filter: saturate(0) brightness(3);
	svg{
		max-width: 70%;
	}
}


.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

$web-animation-delay: 330ms;
$web-animation-delay-long: 500ms;
$web-animation-delay-short: 120ms;

.provider-logos{
	display: grid;
    grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1);
	grid-gap: 10px;
	justify-items: center;
	// display: flex;
	// flex-direction: row;
	// flex-wrap: wrap;
    position: relative;
    justify-content: center;

	@include break-max($break-med) {
		grid-template-columns: repeat(3, 1fr) !important;
		// grid-template-rows: repeat(2, 1fr) !important;
		.provider-logo{
			transform: scale(.9);
		}
	}

	@include break-max($break-xs) {
		grid-template-columns: repeat(2, 1fr) !important;
		// grid-template-rows: repeat(2, 1fr) !important;
		.provider-logo{
			transform: scale(.8);
		}
	}

	.provider-logo{
		opacity:0;
		transform: translateY(-4px);

		flex: 1;
		margin: 0 16px;

		align-items: center;
		justify-content: center;
		max-width: 122px;
		&:hover{
			transition-delay: 0;
			opacity: 1 !important;
			transform: translateY(0) scale(1.05) !important;
		}
		// display: inline-block;

		img{
			max-width: 100%;
		}
	}

	// .provider-logo:nth-child(n+5):nth-child(-n+7) {
	//   grid-row: 2;
	//   justify-self: center;
	// }



}



// loop through the child elements and set the transition delay
// based on the child's index multiplied by the delay variable

// *:nth-child(1){
// 	transition-delay: 500ms;
// }
.animate-in{
	.provider-logo{
		opacity: .7 !important;

		&:hover{
			opacity: 1 !important;
			transition-delay: 0;
		}
		transform: translateY(0);
	}
}

.animate-children{
	> *:not(.animate-off){
		opacity: 0;
		transform: translateY(-4px);
		-webkit-transition: all $web-animation-delay;
		-moz-transition: all $web-animation-delay;
		-o-transition: all $web-animation-delay;
		transition: all $web-animation-delay;

		&:not(.animate-on){

		}

		@for $i from 1 through 13 {
		  > *:nth-child(#{$i}) {
			transition-delay: calc(#{$i} * #{$web-animation-delay-short});
		  }
		}
	}
	&.animate-in{
		> *{
			opacity: 1;
			transform: translateY(0);

		}
	}
}

.content-panel{
	background: $color-panel-main;
	padding:33px 44px;
	display: flex;
	flex-direction: column;
	align-items: center;


}

.content-row{
	display: flex;
	flex-direction: row;

	@include break-max($break-sm) {
		flex-direction: column;
	}
	width: 100%;
	align-items: center;
	justify-content: space-around;
	margin-top: 20px;


	.content-panel-element{
		display: inline-block;
		width: 33.33%; /* 3 elements per row */
		text-align: center;
		max-width: 220px;
		margin: 7px;

		@include break-max($break-sm) {
			margin-top: 33px;
			width: 90%;
		}
		h6{
			color: $color-text;
			font-size: 14px;
			margin-top: 11px;
		}
		p,h6{
			margin-bottom: 0;
			text-align: center;
		}
		p{
			font-size: 14px;
			font-weight: 600;
			line-height: 18px;
			margin-top: 5px;
			max-width: 100%;
		}
		img{
			width: 90%;
			// // min-height: 250px;
			// color: transparent;
			border:none;
			border-radius: $radius-md;
			aspect-ratio: 1;
			// background: #000;
			// margin-bottom: 7px;
		}
	}
}


.hero-logo{
	position: absolute;
	bottom: 0;
	left:44px;
	max-width: 64%;
	z-index: 7;
	padding-bottom:44px;
	img{
		max-width: 100%;
	}
}

@keyframes arrowAnimation {
  0% { opacity: 0.5; } /* Start at 50% opacity */
  50% { opacity: 1; } /* Fade to 100% opacity */
  100% { opacity: 0.5; } /* Fade back to 50% opacity */
}

/* Apply the animation to the div */


.hero-image-container{
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	// pointer-events: none;
	.hero-scroll-arrow{
		position: absolute;
	    left: 50%;
	    bottom: -5px;
	    margin-left: -14px;
	    padding: 11px;
	    z-index: 99;
	    font-size: 44px;
		animation: arrowAnimation 2s infinite;
		cursor: pointer;
		border-radius: $radius-md;
		&:hover{
			// background: $color-button-action-active;
			opacity: 1;
			animation: none;
		}
	}
	.hero-image{
		// background-image: url(/media/photos/wallowas.jpg);
		position: absolute;

		// top: 50%;
		// left: 50%;
		// transform: translate(-50%, -50%);
		width: calc( 20px + 100% );
		height: calc( 40px + 100% );
		transition: transform .33s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		// filter: brightness(0.9);
		filter: saturate(0) brightness(0.77);
		background-size: cover;
		background-position: center;
	}

	.grid-canvas{
		position: absolute;
		&.lobby{
			opacity: .33;
		}

		// transition: all .5s;
	}

	canvas{
		position: absolute;
		// width: 100%;
		// height:100%;
		z-index: 33;
	}


}

.hero-image-stack{
	width:84%;
	margin:44px;
	position: absolute;
	z-index: 33;
	max-height: 33vh;
	max-width: 640px;
	> img{
		max-height: 33vh;
		max-width: 80%;
		position:absolute;
		border-radius: 7px;
		box-shadow: $shadow-panel;
	}
}

.hero-hue{
	position: absolute;
	z-index: 44;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.hero-hue-blue{

		position: absolute;
		z-index: 4;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background:-moz-radial-gradient(circle at 0% 100%, rgba(0, 72, 255, 1) 0%, rgba(130, 139, 238, 0) 98%);

		/* safari 5.1+,chrome 10+ */
		background:-webkit-radial-gradient(circle at 0% 100%, rgba(0, 72, 255, 1) 0%, rgba(130, 139, 238, 0) 98%);

		/* opera 11.10+ */
		background:-o-radial-gradient(circle at 0% 100%, rgba(0, 72, 255, 1) 0%, rgba(130, 139, 238, 0) 98%);

		/* ie 10+ */
		background:-ms-radial-gradient(circle at 0% 100%, rgba(0, 72, 255, 1) 0%, rgba(130, 139, 238, 0) 98%);

		/* global 92%+ browsers support */
		background:radial-gradient(circle at 11% 98%, rgb(5 34 105) 0%, rgb(5 34 105) 7%, rgba(130, 139, 238, 0) 52%);
		
		background: radial-gradient(circle at 99% 98%, rgb(61 24 91) 0%, rgb(95 51 113) 7%, rgba(130, 139, 238, 0) 52%);
		background: radial-gradient(circle at 99% 98%, rgb(24 34 91) 0%, rgb(51 56 113) 7%, rgba(130, 139, 238, 0) 52%);
	}

	.hero-hue-dark-corner{
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		// background: radial-gradient(circle at 104% 8%, rgb(0 0 0) 0%, rgba(0, 0, 0,.88) 22%, rgba(130, 139, 238, 0) 88%);
		background: radial-gradient(circle at 44% 125%, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.88) 42%, rgba(130, 139, 238, 0) 88%);
	}



}



.hero-text{
	position: absolute;
	bottom: 33px;
	left: 64px;
	z-index: 113;
	max-width: 80%;
	p{
		font-size: 14px;
		font-weight: 600;
		color:#65b4f2;
		color:$color-white;
	}
	@include break-max($break-med) {
		p{
			font-size: 10px;
		}
	}
	h6{
		margin: 0;
		opacity: .5;
	}
}
.ethereum-logo{
	position: absolute;
	bottom: 33px;
	right: 47px;
	z-index: 39;
	width: 60px;
	filter: brightness(0.55);
	// opacity: .33;
	&:hover{
		filter: brightness(0.88);
		// opacity: .55;
	}
	img{
		max-width: 100%;
	}
}

.typewriter-wrapper{
	position: absolute;
	width:80%;
    top: 64px;
    left: 53px;
}
.Typewriter{
	
    font-size: 19px;
    font-weight: 500;
    color: #a4cce8;
    width: 80%;
    z-index: 44;
    color: #0be5ff;
	line-height: 24px;

	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;

	// @include break-max($break-med) {

	// 	font-size: 44px;

	// }

	// @include break-max($break-xs) {

	// 	font-size: 33px;

	// }

	// color: #a4cce8;

	width: 80%;
	z-index: 44;
	// color: $color-text;
	// @include break-max($break-med) {
	// 	font-size: 44px;
	// }
}

.token-dashboard-header{
	background: #18191b;
    border-bottom: 1px solid #1f1f1f;
    border-top: 1px solid #1f1f1f;
    padding: 7px;
}

.animate-container{
	&.slide-right{
		transform: translateX(-11px);
	}
	&.slide-left{
		transform: translateX(11px);
	}


	opacity: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	padding: 55px 5%;
	&.image-left{
		.slide-in-image{

		}
		.slide-in-text{
			padding-left: 33px;
			max-width: 311px;
		}
	}
	&.image-right{
		.slide-in-image{

		}
		.slide-in-text{
			padding-right: 44px;
			max-width: 311px;
		}
	}

	@include break-max($break-sm) {
        flex-direction: column;
		.slide-in-text{
			padding: 0 !important;
		}
		.image-left,.image-right{
			order:1;
		}
		.slide-in-text{
			order:2;
			margin-top: 22px;
			h1{
				font-size: 33px;
			}
		}
		padding: 16px 5%;
    }

}

.slide-in-image{
	flex: 1;

	margin:22px;
	display: flex;
    justify-content: center;
    align-items: center;
	@for $i from 1 through 8 {
	  &:nth-child(#{$i}) {
		margin-top:22px;
		margin-left: 11px;
	  }
	}
	&.wide{
		flex:1.5;
	}
	img{
		max-width: 100%;
		border-radius: $radius-lg;
		box-shadow: $shadow-panel-sub;
	}
}

.app-features{
	.slide-in-text{
		padding-top: 33px;
	}
}
.slide-in-text{
	flex: 1;


	*{
		-webkit-transition: all $duration-transition-long;
	    -moz-transition: all $duration-transition-long;
	    -o-transition: all $duration-transition-long;
	    transition: all $duration-transition-long;
	}

	@for $i from 1 through 8 {
	  > *:nth-child(#{$i}) {
		transition-delay: calc(#{$i} * #{$web-animation-delay});
	  }
	}

	h4,h2{
		margin-bottom: 4px;
		min-width: 120px;
		// transition-delay: 250ms;
	}

	p{
		transition-delay: 1100ms;
	}

	@include break-max($break-med) {
		p{
			max-width: initial;
		}
	}

	*{
		transform: translateY(-11px);
		opacity: 0;
		margin-bottom: 0;
	}
	// h1{
	// 	transform: translateY(-11px);
	// 	margin-left: -2px;
	// 	margin-bottom: 7px;
	// 	opacity: 0;
	// 	transition-delay: 500ms;
	// }
	.icon, .svg-icon{
		margin-right: 13px;
	}
	.icon{
		font-size: 18px;
	}
	.svg-icon{
		width:20px;
		height:20px;
	}
	p{
		// transform: translateY(-11px);
		font-size: 15px;
		font-weight: 500;
		line-height: 20px;
		@include break-max($break-med) {
			font-size: 14px;
			line-height: 21px;
		}

		text-align: left;
		// line-height: 24px;
		// transition-delay: 1s;
		opacity: 0;

	}

	.bold{
		font-weight: 600;
	}
	&.center{
		*{
			text-align: center !important;
		}


		display: flex;
	    align-items: center;
	    flex-direction: column;
	}
}



.animate-in {
	&.slide-left{
		transform: translateX(0);
	}
	&.slide-right{
		transform: translateX(0);
	}
	.slide-in-text{
		*{
			transform: translateY(0);
			opacity: 1;
		}
	}

    opacity: 1;
  // animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0);
  }
}




.parallax-image{
	position: absolute;
	z-index: -2;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.border-cap{
	border-top: 3px solid #0f0f0f;
	border-bottom: 3px solid #0f0f0f;
}


.stripebg{
	background: repeating-linear-gradient(45deg, #0c2228, #0b0f1b 7px);
	background: repeating-linear-gradient(45deg, #0c22286b, #0b0f1b61 7px);
}

.timeline{
	position: relative;
	overflow: hidden;
	padding:55px 33px;
	@include break-max($break-med) {
		padding: 42px 33px;
	}
	// background: #222;
	// background-image: linear-gradient(315deg, rgb(13, 24, 30) 0%, rgb(25 28 30 / 86%) 74%);
	background: #0b0b0bb0;
	.line{
		position: absolute;
		left:50%;
		width:4px;
		margin-left:-2px;
		@include break-max($break-med) {
			left:33px;
		}
		top:62px;
		// bottom:55px;
		// height: calc( 100% - 110px );
		// height: 444px;
		background: #444;
		border-radius: 2px;
		.arrow{
			opacity: 0;
			position: absolute;
			bottom: 0;
			-webkit-transition: opacity $web-animation-delay;
			-moz-transition: opacity $web-animation-delay;
			-o-transition: opacity $web-animation-delay;
			transition: opacity $web-animation-delay;
			transform: translate(-6px, 12px) scaleY(0.75);
			@include break-max($break-med) {
				left:2px;
			}
			&.show{
				opacity: 1;
			}
		}
	}

	.moon{
		position: absolute;
		bottom:-27px;
		left:50%;
		z-index: 33;
		margin-left: -9px;
		-webkit-transition: all 7s;
		-moz-transition: all 7s;
		-o-transition: all 7s;
		transition: all 7s;
		svg{
			fill: #82a01c;
		}

	}
}


.timeline-segment{
	width: 100%;
	position: relative;
	// background: #13181b;
	min-height: 240px;

	> *{
		-webkit-transition: all $web-animation-delay;
		-moz-transition: all $web-animation-delay;
		-o-transition: all $web-animation-delay;
		transition: all $web-animation-delay;
	}






	.circle{
		margin-left: 50%;
		@include break-max($break-med) {
			margin-left: 0;
		}
		transform: translateX(-50%);
		opacity: 0;
		width:18px;
		height:18px;
		border-radius: 50%;
		background: $color-text;
	}


	@include break-min($break-med) {
		&.panel-right .panel{
			left: 50%;
			width: calc( 50% - 27px);
		}

		&.panel-left .panel{
			left: 50%;
			width: calc( 50% - 55px);
			transform: translateX(-100%);
			margin-left: -24px;
			margin-top: -20px;
			h4{
				text-align: right;
			}
			ul{
				padding-right: 0;
				display: flex;
			    flex-direction: column;
			    align-items: flex-end;
			}
		}
	}

	@include break-max($break-med) {
		&.panel-left .panel, &.panel-right .panel{
			left: 55px;
			left: 22px;
			width: calc( 90% - 55px);
			// transform: translateX(-100%);
			// margin-left: -24px;
			// margin-top: -20px;
		}

		// &.panel-left .panel{
		// 	// left: 50%;
		// 	width: calc( 50% - 55px);
		// 	transform: translateX(-100%);
		// 	margin-left: -24px;
		// 	margin-top: -20px;
		// 	h4{
		// 		text-align: right;
		// 	}
		// 	ul{
		// 		padding-right: 0;
		// 		display: flex;
		// 	    flex-direction: column;
		// 	    align-items: flex-end;
		// 	}

	}


	.panel{
		opacity: 0;
		transition-delay: 500ms;
		box-shadow: $shadow-panel;
		padding: 11px 19px;
	    position: relative;
	    transform: translate(27px, -22px);
		// background: #21333b;
		// background-image:  linear-gradient(315deg, rgb(14 37 50) 0%, #182d3c 74%);
		background-image: linear-gradient(315deg, rgb(12 15 17) 0%, #1e2529db 74%);

		border-radius: $radius-md;
		max-width: 444px;


		*{
			opacity:0;
		}
		.mute{
			opacity:.7 !important;
			font-size: .7em;
			&.small{
				font-size: .55em;
			}
		}
		h4{
			margin-bottom: 11px;
		}
		@for $i from 1 through 11 {
		  > *:nth-child(#{$i}) {
			transition-delay: calc(#{$i} * #{$web-animation-delay-short});
		  }
		}
		ul{
			opacity: 0;
			padding-left: 22px;
			list-style: none;
			padding-left: 3px;
			margin-bottom: 7px;
			li{
				-webkit-transition: all $web-animation-delay;
				-moz-transition: all $web-animation-delay;
				-o-transition: all $web-animation-delay;
				transition: all $web-animation-delay;

				margin-bottom: 3px;
			    padding-left: 7px;
			    position: relative;

				line-height: 21px;
				@include break-max($break-med) {
					line-height: 19px;
				}
			}
			li::before {
				content: "\2022"; /* Add a bullet point as the icon */
				color: $color-brand; /* Set the color of the icon */
				font-size: 22px;
				margin-top: -3px;
			    /* margin-right: 5px; */
			    line-height: 13px;

				@include break-max($break-med) {
					line-height: 11px;
				}
			    transform: translateY(50%);
			    position: absolute;
			    left: -4px;
			}
			@for $i from 1 through 15 {
			  > *:nth-child(#{$i}) {
				transition-delay: calc(#{$i} * #{$web-animation-delay});
			  }
			}
		}
	}

	&.animate-in{
		.circle{
			opacity: 1;

		}
		.panel{
			opacity: 1;
			*{
				opacity:1;
			}
		}
	}
}

table.tokenomics-table{
	width: 80%;
    text-align: left;
	max-width: 720px;
	border-collapse: collapse;
	table-layout: fixed;
    border-spacing: 0;
	td{
		padding: 0;
	}
	p{
		font-size: 15px;
	}
	border-radius: $radius-md;
	overflow: hidden;
}

.tokenomics-table-head{
	tr{
		background: $color-brand;
	}
	p{
		font-weight: 600;
		margin: 0;
	}

	th:first-child{
		.tokenomics-table-cell{
			justify-content: flex-start;
		}
	}

	.tokenomics-table-cell{
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 7px;
		color: $color-grey-darkest;
	}
}

.tokenomics-table-body{

	tr:nth-child(even) {
		background: $color-grey-darker;
	}

	tr:nth-child(odd) {
	    background: $color-grey-dark;
	}
	tr{
		td:first-child{
			border-right:1px solid $color-grey02;
			padding-left: 3px;
			.tokenomics-table-cell{
				justify-content: flex-start;
			}
			p{
				font-weight: 600;
				margin: 0;
			}
		}
		.tokenomics-table-cell{
			display: flex;
			align-items: center;

			justify-content: center;


			padding: 33px 7px;
			p{
				margin:0;
			}

			display: flex;

		}
	}
}



// body.inWebsite{
//   position: relative;
//   height: 100vh;
//   background: linear-gradient(to right, red, orange);
//   animation: gradient 33s ease-in-out infinite;
// }
//
// body.inWebsite:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
//   animation: waves 33s ease-in-out infinite;
// }
//
// body.inWebsite:after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
//   animation: waves 99s ease-in-out infinite;
// }
//
// @keyframes gradient {
//   0% {
//     background: linear-gradient(to right, red, orange);
//   }
//   20% {
//     background: linear-gradient(to right, orange, yellow);
//   }
//   40% {
//     background: linear-gradient(to right, yellow, green);
//   }
//   60% {
//     background: linear-gradient(to right, green, blue);
//   }
//    80% {
//     background: linear-gradient(to right, blue, indigo);
//   }
//   100% {
//    background: linear-gradient(to right, indigo, red);
//  }
// }
//
//
//
// @keyframes waves {
//   0% {
//     transform: translateX(-99%);
//   }
//   16% {
//     transform: translateX(-33%);
//   }
//   33% {
//     transform: translateX(33%);
//   }
//   50% {
//     transform: translateX(99%);
//   }
//
//   66% {
//     transform: translateX(33%);
//   }
//
//   84% {
// 	transform: translateX(-33%);
//   }
//
//   100% {
// 	transform: translateX(-99%);
//   }
//
//
// }
