/*******************************************
Market Chart Styles
*******************************************/


.dex-stage{
    height: calc(100vh - 44px);
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dex-chart, .dex-swap{
    // height: calc(100vh - 44px);


    iframe{
        width: 100%;
        // height: calc( 100% + 44px ) !important;
        height: 100%;
        border: 0;
        html{
            display: none !important;
        }
    }
}
.dex-chart{
    flex: 1;
    position: relative;
    // height: calc(100vh - 44px);
    width: 100%;
    overflow: hidden;
}

.dex-footer{
    position: absolute;
    height: 44px;
    // background: $color-panel-sub;
    background: rgb(18 22 25);
    width: 100%;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    font-weight: 500;
    line-height: 17px;
    align-items: center;
    padding: 0 11px;
    p{
        opacity: .5;
        font-size: 10px;
        font-weight: 600;
        margin: 0;
    }
}

.dex-swap{

    // background-color: $color-panel-sub;
    background-color: rgb(13 17 19);

    display: flex;
    // position: absolute;

    height: 100%;

    &.draggable{
        z-index: 99999;
        width: 535px;
        border-radius: 7px;
        height: 75vh;
        box-shadow: $shadow-tight;

    }

    // top: 24px;
    // left: 88px;
    overflow: hidden;

    position: relative;
    // border: 5px solid #333;

    flex-direction: column;

    .qr-code-panel{
        position: absolute;
        right: 12px;
        bottom: 64px;
        cursor: pointer;
    }

    .dex-swap-header{
       
        .uniswap-logo{
            height: 27px;
            margin: 3px;
            width: auto;
        }

        .sushiswap,.kibaswap{
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        .icon-action-button{
            right: 6px;
            top: 6px;
            width: 25px;
            height: 25px;
        }
    }

    .dex-swap-footer, .dex-swap-header{
        display: flex;
        flex-direction: row;
        background-image: url(/media/textures/dropbar3.png);
    	background-repeat: repeat-x;
        

        .coin-link:not(.normal){
            .svg-icon{
                width: 27px;
                height: 27px;
                filter: saturate(0);
                img{
                    filter:none;
                }

            }
            &:hover, &.active{
                .svg-icon{
                    filter: none;

                    &.uniswap svg{
                        fill: #FF007A;
                        path{
                            fill: #FF007A;
                            stroke: #FF007A;
                        }

                    }
                }
            }
        }



    }


}

.react-draggable{
    z-index: 999;
    position: absolute;

}

.token-toolbar-draggable{
    min-width: 222px;

    .toolbar{
        padding: 3px;
        margin: auto;
    }
    .identicon{
        width: 22px !important;
        height: 22px !important;
        margin: 4px;
    }


    .coin-links{
        padding: 5px;
        justify-content: space-around;
    }
    .coin-name,.coin-symbol{
        pointer-events: none;
    }


    button{
        max-height: 26px;
        margin: 0;
    }


    .icon-action-button{
        padding: 4px;
        width: 22px;
        height: 22px;
        right: 8px;
        top: 8px;

    }
}



.drag-handle{
    width: 21px;
    padding: 7px;
    // cursor: pointer;
    // margin-right: 11px;
    display: flex;
    flex-direction: row;
    .svg-icon{
        width: 11px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        // box-shadow: $shadow-tight;
        svg{
            fill: rgb(76 111 144 / 50%);;
        }
    }
    // &:hover{
    //     svg{
    //         fill: rgb(76 111 144 / 80%);;
    //     }
    // }
}

.swap-header-chain-icon{
    // position: absolute;
    // left: 34px;
    // top: 8px;
    margin-left: 11px;
    filter: saturate(0) brightness(.5);
}

.token-toolbar-header{
    padding: 3px;
    pointer-events: all;
    display: flex;
    flex-direction: row;
}


.market-chart{
    width:100%;
    height:auto;
    // margin-bottom:6px;
    // min-height:300px;
    // background: #333;

}

.market-chart-line-area{
    width: calc( 100% + 37px );
    height: calc( 100vh - 155px );
    margin-left: -37px;
    // pointer-events: none;
}

.no-pointer-events{
    pointer-events: none;
}

.chart-wrapper{
    width: 50%;
    padding: 15px;
    background: #1d1d1d;
    border-radius: 8px;
    margin: 10px;
    // max-width: 320px;
    // &:last-child{
    //     margin-left: 5px;
    // }

    h2{
        font-size: 18px;
        font-weight: 600;
    }



}

.chart-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 33px;
    margin: 15px;
    position: absolute;
    z-index: 9999;
}

.chart-coin{
    // width: 144px;
    background: rgba(44, 55, 55, 0.5);
    padding: 19px 10px;
    padding-left:27px;
    padding-right: 33px;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover, &.active{
        background: rgba(37,37,37, 0.84);
    }
    &.active{
        background: rgba(51,54,57, 0.84);
        cursor: default;
    }
    .icon-action-button{
        padding: 2px;
        width: 15px;
        height: 14px;
        right: 8px;
        top: 50%;
        margin-top: -5px;
    }
    .icon-action-button.eye{
        left: 5px;
        margin-top: -7px;
    }
    .coin-icon{
        width: 20px;
        height: 20px;
    }
    .active-green-circle{
        width:6px;
        height:6px;
        top:11px;
    }
    .color-column{
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
    }
    &.muted{
        .color-column{
            background: transparent !important;
        }
        .coin-icon{
            filter: saturate(0);
            opacity: .5;
        }
    }
}

.chart-time-filter{
    padding-left:10px;
}

.svg-icon{
    width:24px;
    height:24px;
    &.exchange, &.small{
        width: 18px;
        height: 18px;
    }

    &.xsmall{
        width: 13px;
        height: 13px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(.no-fill){
        &:not(.raw){
            svg{
                fill: $color-text !important;
                path, polyline{
                    fill: $color-text;
                    stroke: $color-text;
                }
            }
        }
    }

    &.no-fill{
        &:not(.raw){
            svg{
                stroke: $color-text;
                path, polyline{
                    
                    stroke: $color-text;
                }
            }
        }
    }
   
    svg{

        max-width: 100%;
        max-height: 100%;

    }

    &.green{
        svg{
            fill: $color-green-standard;

            path{
                fill: $color-green-standard;
            }
        }
    }
    &.nocolor{
        svg{
            fill: initial;
            path{
                fill: initial;
            }
        }
    }
}

.chart-tools{
    padding: 5px;
    background: $color-panel-data;
    height: 37px;
    border-radius: $radius-sm;
    display: flex;
    flex-direction: row;
    margin:4px;

    .chart-tool-button{
        background: $color-panel-sub;
        border-radius: $radius-sm;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-right:5px;
        .svg-icon{
            width:24px;
            height:24px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                fill: $color-text;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &.active, &:hover{
            background: $color-panel-sub-item-roll;

            .svg-icon{
                svg{
                    fill: $color-brand-blue;
                }
            }
        }
        &.active{
            cursor: default;
        }
    }

    .chart-tool-button:last-child{
        margin-right:0;
    }
}
